import React from "react";
import { useSnapshot } from "valtio";
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ModalTransition from "../../../../../../../shared/components/page/transitions/ModalTransition";
import caseTypeStore from "../../../caseTypeStore";
import { dummyForms } from "../../../../forms/table/dummyForms";
import { CaseForm, DynamicCaseForm } from "../../../../../../../shared/models/caseForm.model";

const FormSelector = () => {
  const snap = useSnapshot(caseTypeStore);

  const handleClose = () => {
    caseTypeStore.formSelectOpen = false;
  };

  const handleSelectForm = (form: { id: number; name: string }) => {
    // TODO link form and close
    caseTypeStore.formSelectOpen = false;
  };

  const handleCreateNewForm = () => {
    caseTypeStore.formBuildOpen = true;
    caseTypeStore.formToEdit = new CaseForm();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={snap.formSelectOpen}
      onClose={handleClose}
      TransitionComponent={ModalTransition}
      PaperProps={{ style: { overflowX: "hidden", backgroundColor: "#f9f9f9" } }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Main content area */}
      <Box sx={{ px: 2, pb: 2, pt: 1 }}>
        <Typography variant="subtitle1" gutterBottom>
          Select a form to link to this case type (doesnt work, all dummy data, need apis):
        </Typography>

        {/* List of existing forms */}
        <List>
          {dummyForms.map((form) => (
            <ListItemButton
              key={form.id}
              onClick={() => handleSelectForm(form)}
              sx={{
                backgroundColor: "white",
                mb: 1,
                borderRadius: 1,
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <ListItemText primary={form.name} />
            </ListItemButton>
          ))}

          {/* Create New Form option */}
          <ListItemButton
            sx={{
              mt: 2,
              border: "1px dashed #ccc",
              borderRadius: 1,
            }}
            onClick={handleCreateNewForm}
          >
            <AddIcon sx={{ mr: 1 }} />
            <ListItemText
              primary="Create New Form"
              primaryTypographyProps={{ sx: { fontWeight: "bold" } }}
            />
          </ListItemButton>
        </List>
      </Box>
    </Dialog>
  );
};

export default FormSelector;
