import React, { useCallback, useEffect, useState } from "react";
import { CasesService } from "../../../../../../../shared/services/cases/casesService";
import { GroupEntry } from "../../../../../../../shared/models/groupEntry.model";
import useAppStore from "../../../../../../../appStore";
import { FormControl, MenuItem, Select } from "@mui/material";
import caseTypeStore from "../../../caseTypeStore";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";

const DepartmentLink = () => {

  const snap = useSnapshot(caseTypeStore);
  const {t} = useTranslation();
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState<GroupEntry[]>([]);

  const fetchDepartments = useCallback(async () => {
    setIsLoading(true);
    try {
      const service = new CasesService();
      const data = await service.getDepartments(user?.organization.id);
      setDepartments(data);
    } catch (error) {
      console.error('Error fetching departments:', error);
    } finally {
      setIsLoading(false);
    }
  }, [user.organization.id]);

  useEffect(() => {
    fetchDepartments().then();
  }, [fetchDepartments]);

  const handleDepartmentChange = (department: GroupEntry) => {
    if (caseTypeStore.selectedCaseType) {
      caseTypeStore.selectedCaseType.department = department;
      //TODO update on selection so you don't have to click save
    }
  };

  return (
    <FormControl fullWidth style={{ marginBottom: 15 }}>
      <span className="text-gray-700" style={{fontSize: '0.9rem'}}>{t('department')}</span>
      <Select
        value={snap.selectedCaseType?.department?.id || ''}
        style={{height: '45px', borderRadius: '8px'}}
      >
        {departments.map((department) => (
          <MenuItem key={department.id} value={department.id} onClick={() => handleDepartmentChange(department)}>
            {department.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DepartmentLink;