import React, { useEffect, useState } from "react";
import TextAreaField from "../tell-sage/case-creation-assist/reusable-fields/TextAreaField";
import SelectDropdownField from "../tell-sage/case-creation-assist/reusable-fields/SelectDropdownField";
import DateField from "../tell-sage/case-creation-assist/reusable-fields/DateField";
import RadioButtonField from "../tell-sage/case-creation-assist/reusable-fields/RadioButtonField";
import casesStore from "../../caseStore";
import { useSnapshot } from "valtio";
import useAppStore from "../../../../appStore";
import CaseEmployeeField from "../tell-sage/case-creation-assist/reusable-fields/CaseEmployeeField";
import PhotoUploaderViewer from "../../../../shared/components/media/media-viewers/photos/PhotoUploaderViewer";
import { Media } from "../../../../shared/models/media.model";
import DepartmentSelection from "./components/DepartmentSelection";
import CaseTypeSelection from "./components/CaseTypeSelection";
import CaseLocationSelection from "./components/CaseLocationSelection";
import CaseReadOnly from "../edit-case/components/details/read-only/CaseReadOnly";
import CreatedCaseCard from "../../../sage-virtual-assistant/created-items-assist/CreatedCaseCard";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import CaseContactField from "./components/CaseContactSelect";
import CaseServiceField from "./components/CaseServiceField";
import CaseWhereAndWhat from "./components/CaseWhereAndWhat";

const DynamicCaseFormSection = ({section, manual=false}) => {

  const snap = useSnapshot(casesStore);
  const [media, setMedia] = useState<Media[]>(casesStore.caseToEdit.media);
  const {selectedLocation} = useAppStore();

  useEffect(() => {
    if (casesStore.caseToEdit.related_to_employees?.length > 0) {
      casesStore.caseToEdit.location =
        casesStore.caseToEdit.related_to_employees?.[0]?.works_at?.[0]?.location
        ?? selectedLocation;
    }
  }, [snap.caseToEdit.related_to_employees]);

  useEffect(() => {
    casesStore.caseToEdit.media = media;
  }, [media]);

  const handleCustomFormInputChange = (fieldName, value) => {
    casesStore.caseToEdit.form_data[fieldName] = value;
  };

  const handleModelTextAreaChange = (fieldName, value) => {
    casesStore.caseToEdit[fieldName] = value;
  };

  if (snap.formLoading) {
    return (
      <CustomLoadingDots />
    )
  }

  return (
    <div>
      {section.fields && section.fields.map((field, index) => {
        switch (field.type) {
          case "Department":
          return (
              <DepartmentSelection manual={manual}/>
            );
          case "CaseType":
          return (
              <CaseTypeSelection manual={manual}/>
            );
          case "Location":
            if (field.data_source === "$model.location") {
              return (
                <CaseLocationSelection />
              );
            } else {
              return <CustomError error={'Location is only supported for data_source $model.location'} />;
            }
          case "EmployeeSelect":
              if (field.data_source.includes('$model')) {
                return (
                    <div style={{ marginBottom: 15 }}>
                      <span className="text-gray-700">{field.name}</span>
                      <CaseEmployeeField fieldName={field.data_source.replace('$model.', '')} />
                    </div>
                  );
              } else {
                return <CustomError error={'EmployeeSelect is only available for model fields currently'} />;
              }
          case "ContactSelect":
            if (field.data_source.includes('$model')) {
              return (
                <div style={{ marginBottom: 15 }}>
                  <span className="text-gray-700">{field.name}</span>
                  <CaseContactField />
                </div>
              );
            } else {
              return <CustomError error={'ContactSelect is only available for model fields currently'} />;
            }
          case "ServiceSelect":
            if (field.data_source.includes('$model')) {
              return (
                <div style={{ marginBottom: 15 }}>
                  <span className="text-gray-700">{field.name}</span>
                  <CaseServiceField fieldName={field.data_source.replace('$model.', '')} />
                </div>
              );
            } else {
              return <CustomError error={'ServiceSelect is only available for model fields currently'} />;
            }
          case "WhereAndWhat":
            if (field.data_source.includes('$model.where_and_what')) {
              return (
                <div style={{ marginBottom: 15 }}>
                  <span className="text-gray-700">{field.name}</span>
                  <CaseWhereAndWhat />
                </div>
              );
            } else {
              return <CustomError error={'WhereAndWhat is only available for $model.where_and_what'} />;
            }
          case "Media":
            if (field.data_source === ('$model.media')) {
              return (
                <>
                  <span className="text-gray-700">Attachments</span>
                  <PhotoUploaderViewer
                    item={casesStore.caseToEdit}
                    setMedia={setMedia}
                    captions={snap.caseToEdit.status === "Closed"}
                    module={"cases"}
                  />
                </>
              );
            } else {
              return <CustomError error={'Media field type is only supported for data_source $model.media'} />
            }
          case "TextArea":
            if (field.data_source.includes('$model')) {
              return (
                <TextAreaField
                  label={field.name}
                  value={snap.caseToEdit.description}
                  onChange={(e) => handleModelTextAreaChange(field.data_source.replace('$model.', ''), e.target.value)}
                  multiline
                  rows={5}
                />
              )
            } else {
              return (
                <TextAreaField
                  key={index}
                  value={snap.caseToEdit.form_data[field.data_source] || ""}
                  onChange={(e) =>
                    handleCustomFormInputChange(
                      field.data_source,
                      e.target.value
                    )
                  }
                  label={field.name}
                  multiline={true}
                  rows={5}
                />
              );
            }

          case "TextField":
            return (
              <TextAreaField
                key={index}
                value={snap.caseToEdit.form_data[field.data_source] || ""}
                onChange={(e) =>
                  handleCustomFormInputChange(
                    field.data_source,
                    e.target.value
                  )
                }
                label={field.name}
                multiline={false}
                rows={1}
              />
            );
          case 'Select':
            return (
              <SelectDropdownField
                key={index}
                label={field.name}
                value={snap.caseToEdit.form_data[field.data_source] || ""}
                onChange={(event) => handleCustomFormInputChange(field.data_source, event.target.value)}
                options={[
                  { value: 'Abrasion, Scrapes, Cut', label: 'Abrasion, Scrapes, Cut' },
                  { value: 'Sprain, Strain', label: 'Sprain, Strain' },
                  { value: 'Burn', label: 'Burn' },
                  { value: 'Pain', label: 'Pain' },
                  { value: 'Nausea', label: 'Nausea' },
                  { value: 'Headache, Dizziness', label: 'Headache, Dizziness' },
                  { value: 'Difficulty Breathing', label: 'Difficulty Breathing' },
                  { value: 'Slip, Trip, or Fall', label: 'Slip, Trip, or Fall' },
                  { value: 'Heat Exhaustion', label: 'Heat Exhaustion' },
                  { value: 'Drug Use', label: 'Drug Use' },
                  { value: 'Eye / Vision', label: 'Eye / Vision' },
                  { value: 'Other', label: 'Other' },
                ]} />
            );
          case "Date":
            return (
              <DateField
                key={index}
                label={field.name}
                value={snap.caseToEdit.form_data[field.data_source] || ""}
                onChange={(newValue) =>
                  handleCustomFormInputChange(field.data_source, newValue)
                }
              />
            );
          case "Radio":
            return (
              <RadioButtonField
                key={index}
                label={field.name}
                value={snap.caseToEdit.form_data[field.data_source] || "No"}
                onChange={(event) =>
                  handleCustomFormInputChange(
                    field.data_source,
                    event.target.value
                  )
                }
              />
            );
          case "Summary":
            return (
              <CaseReadOnly />
            );
          case "WorkItemCreation":
            if (section.show_after_creation) {
              return (
                <CreatedCaseCard item={casesStore.caseToEdit} />
              );
            } else {
              return <CustomError error={'WorkItemCreation can only be enabled when section.show_after_creation is true'} />
            }
          case "Submitted":
            return (
              <CreatedCaseCard item={casesStore.caseToEdit} workItem={false} />
            );
          default:
            return (
              <div />
            )
        }
      })}
    </div>
  );
};

export default DynamicCaseFormSection;