import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { useSnapshot } from "valtio";
import casesStore from "../../../caseStore";
import useAppStore from "../../../../../appStore";
import { LocationModel } from "../../../../../shared/models/location.model";
import { useTranslation } from "react-i18next";


const CaseLocationSelection = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const snap = useSnapshot(casesStore);
  const {t} = useTranslation();

  const handleLocationChange = (location: LocationModel) => {
    casesStore.caseToEdit.location = location;
  };

  return (
    <FormControl fullWidth style={{ marginBottom: 15 }}>
      <span className="text-gray-700">{t('location')}</span>
      <Select
        value={snap.caseToEdit.location?.id || ''}
      >
        {user?.permissions.locations.map((location) => (
          <MenuItem key={location.id} value={location.id} onClick={() => handleLocationChange(location)}>
            {location.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CaseLocationSelection;