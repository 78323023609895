
import React from "react";
import {
  Button, DialogActions,
  DialogContent, Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import ModalTransition from "../../../../../../shared/components/page/transitions/ModalTransition";
import casesStore from "../../../../caseStore";
import { useTranslation } from "react-i18next";
import DynamicCaseFormSection from "../../../dynamic-form/DynamicCaseFormSection";
import { Section } from "../../../../../../shared/models/caseForm.model";
import Box from "@mui/material/Box";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useSnapshot } from "valtio";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";


interface CaseActionModalProps {
  open: boolean;
  sections: Section[];
  onClose: () => void;
}

const CaseActionModal: React.FC<CaseActionModalProps> = ({ open, sections, onClose }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const snap = useSnapshot(casesStore);
  const {t} = useTranslation();


  const handleFinished = async () => {
    onClose();
  };


  return (
    <Dialog
      fullWidth={!isMobile} fullScreen={isMobile}
      maxWidth={isMobile ? undefined : "sm"}
      open={open}
      onClose={handleFinished}
      TransitionComponent={ModalTransition}
    >

      <DialogContent dividers sx={{ maxHeight: isMobile ? "100vh" : "75vh", overflowY: "auto" }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            p: 2,
            mb: 1,
          }}
        >
          <CheckCircleOutlineIcon
            sx={{ mr: 2 }}
            color="success"
          />
          <Typography sx={{fontSize: '1.1rem'}}>
            <strong>{t('case-submitted')}</strong> {sections.length > 0 && `— ${t('select-next-action')}` }
          </Typography>
        </Box>
        {snap.formLoading ? (
          <CustomLoadingDots />
        ) : (
          <>
            {sections.map((section) => (
              <DynamicCaseFormSection section={section} />
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleFinished} color="primary">
          {'Finished'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CaseActionModal;