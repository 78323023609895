import React, { useState } from 'react';
import { Scanner } from '@yudiel/react-qr-scanner';
import useAppStore from "../../../../appStore";
import { ServiceLogService } from "../../../../shared/services/service-logs/serviceLogService";
import QrScanResult from "./QrScanResult";
import ServiceLogViewer from "../../../location-summary/components/service-log/components/ServiceLogViewer";

const QrCodeQuickSelect = ({ onClose }) => {
  const { selectedLocation, appImages } = useAppStore();
  const service = new ServiceLogService();

  const [logsOpen, setLogsOpen] = useState(false);
  const [areaIdToView, setAreaIdToView] = useState(0);

  const [qrScanResultOpen, setQrScanResultOpen] = useState(false);
  const [qrScanMessage, setQrScanMessage] = useState("");

  async function handleScan(result) {
    if (!result?.length) return;

    const rawValue = result[0].rawValue;
    let response = null;

    try {
      const url = new URL(rawValue);
      const sensor = url.searchParams.get('sensor');

      if (!sensor) {
        setQrScanMessage("This QR scanner is not set up yet and there are no log entries.");
        setQrScanResultOpen(true);
        return;
      }

      response = await service.getLogList(selectedLocation.id, 0, 0, sensor);

      const area = response[0];
      if (area) {
        setAreaIdToView(area.id);
        setLogsOpen(true);
      }
      console.log("getLogList response:", response);
    } catch (e) {
      console.error("Error while fetching:", e);
    }
  }

  const handleCloseLogs = () => {
    setLogsOpen(false);
    onClose();
  };

  const handleCloseDialog = () => {
    setQrScanResultOpen(false);
    onClose();
  };

  return (
    <div>
      {!logsOpen && !qrScanResultOpen && (
        <Scanner onScan={handleScan} />
      )}

      {logsOpen && (
        <ServiceLogViewer
          open={logsOpen}
          onActionClicked={handleCloseLogs}
          id={areaIdToView}
        />
      )}

      {qrScanResultOpen && (
        <QrScanResult
          open={qrScanResultOpen}
          onClose={handleCloseDialog}
          message={qrScanMessage}
          image={appImages.iconScanError}
        />
      )}
    </div>
  );
};

export default QrCodeQuickSelect;
