import useAppStore from "../../../../appStore";
import React, { useState } from "react";
import { AppBar, Button, Dialog, DialogActions, IconButton, TextField, Toolbar, Typography } from "@mui/material";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import CloseIcon from "@mui/icons-material/Close";
import { ScannerService } from "../../../../shared/services/qr-scanner/scannerService";
import QrScanResult from "../../client-dashboard/service-log-widget/QrScanResult";

const SensorCodeManualEntry = ({ open, onClose }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const [sensorCode, setSensorCode] = React.useState<string>('');
  const {appImages} = useAppStore();
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [scanResponse, setScanResponse] = useState('');
  const [scanImage, setScanImage] = useState('');


  async function handleSubmit() {
    if (!sensorCode?.length) return;
    const scanUrl = "https://app.sageconnect.io?sensor=" + sensorCode;

    try {
      const response = await new ScannerService().sendScanData(
        user.employee,
        scanUrl
      );
      setScanResponse(`Thank you! Your manual entry for ${response?.entity?.name} has been logged!`);
      setScanImage(appImages.iconScanSuccess)
      setResultDialogOpen(true);
    } catch (e) {
      setScanResponse(`Error during submission of manual entry for sensor ${sensorCode}. This sensor may not be set up yet` );
      setScanImage(appImages.iconScanError)
      setResultDialogOpen(true);
    }
  }


  const handleClose = (): void => {
    setResultDialogOpen(false);
    onClose();
  };



  return (
    <Dialog fullWidth open={open} onClose={onClose} TransitionComponent={ModalTransition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className='d-flex card mt-5 align-items-center justify-content-center'>
        <Typography variant="subtitle1" sx={{
          fontWeight: 'bold',
          fontSize: '1.1rem',
          color: '#3a3b3c',
          marginTop: '20px'
        }}>Sensor Code</Typography>
        <TextField
          value={sensorCode}
          onChange={(e) => setSensorCode(e.target.value)}
          sx={{ marginBottom: "20px", width: '90%' }} />
      </div>
      <DialogActions sx={{ position: 'sticky', bottom: 0, backgroundColor: '#fafafa', zIndex: 2 }}>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={sensorCode === ''}>
          Submit
        </Button>
      </DialogActions>
      {resultDialogOpen && (
        <QrScanResult
          open={resultDialogOpen}
          onClose={handleClose}
          image={scanImage}
          message={scanResponse}
        />
      )}
    </Dialog>
  );
};

export default SensorCodeManualEntry;