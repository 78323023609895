import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { WorkItem } from "../../../../../../../../shared/models/workItem.model";
import { InspectedEntity } from "../../../../../../../../shared/models/inspectedEntity.model";
import useAppStore from "../../../../../../../../appStore";
import { DateTime } from 'luxon';
import CustomError from "../../../../../../../../shared/components/page/popup-dialog/CustomError";
import WhereAndWhatTable from "../../../../../../../work-items/components/edit-work-item/components/where-and-what/WhereAndWhatTable";
import WorkItemForm from "../../../../../../../work-items/components/edit-work-item/components/details/WorkItemForm";
import AssignedTo from "../../../../../../../work-items/components/edit-work-item/components/assigned-to/AssignedTo";
import { WorkItemsService } from "../../../../../../../../shared/services/work-items/workItemsService";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import CustomLoadingDots from "../../../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { useSnapshot } from "valtio";
import { workItemStore } from "../../../../../../../work-items/workItemStore";
import { inspectionButtonContainerStyle } from "../../../../../../../../shared/utils/table-modifier-util/tableDataUtil";
import ErrorIcon from "@mui/icons-material/Error";

interface InspectionWorkItemProps {
  inspectedEntity?: InspectedEntity;
  onActionClicked: (action: string) => void;
}

const InspectionWorkItem: React.FC<InspectionWorkItemProps> = ({ inspectedEntity = null, onActionClicked }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const snap = useSnapshot(workItemStore)
  const [prefill, setPrefill] = useState<WorkItem>(new WorkItem());
  const { selectedLocation, toggleMasterRefresh } = useAppStore();
  const [error, setError] = useState<string>('');
  const [isEditing, setIsEditing] = useState(snap.workItemToEdit.id === 0);
  const [isLoading, setIsLoading] = useState(inspectedEntity !== null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  const initializeWorkItem = () => {
    workItemStore.workItemToEdit.due_date = DateTime.now().toUTC().plus({ days: 1 }).toISO();
    workItemStore.workItemToEdit.created_by = user.employee;
    workItemStore.workItemToEdit.location.id = selectedLocation?.id || user?.employee?.works_at?.[0]?.location?.id || 0;
    workItemStore.workItemToEdit.provider.id = user.organization?.id || 0;
  };

  const getWorkItemPrefill = async () => {
    const entityId = inspectedEntity?.id;
    if (typeof entityId !== 'number' || entityId <= 0) {
      console.log("Invalid or missing entity ID:", entityId);
      return;
    }

    setIsLoading(true);
    try {
      const service = new WorkItemsService();
      const prefilledWorkItem = await service.getPrefill(entityId, 'inspected_entity');
      setPrefill(prefilledWorkItem);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    if (prefill) {
      workItemStore.workItemToEdit.title = prefill?.title || workItemStore.workItemToEdit.title;
      workItemStore.workItemToEdit.description = prefill?.description || workItemStore.workItemToEdit.description;
      workItemStore.workItemToEdit.where_and_what = prefill?.where_and_what || workItemStore.workItemToEdit.where_and_what;
      workItemStore.workItemToEdit.media = prefill?.media || workItemStore.workItemToEdit.media;
      workItemStore.workItemToEdit.source = inspectedEntity ? { type: 'Inspection', inspected_entity: inspectedEntity, case: undefined } : null
    }
  }, [prefill]);

  useEffect(() => {
    if (inspectedEntity && inspectedEntity.id && inspectedEntity.id !== -1) {
      getWorkItemPrefill().then();
    }
  }, [inspectedEntity]);

  useEffect(() => {
    if (!snap.workItemToEdit.id) {
      initializeWorkItem();
    } else {
      const fetchWorkItemDetails = async () => {
        try {
          const service = new WorkItemsService();
          const workItem = await service.getWorkItemDetails(snap.workItemToEdit.id);
          workItemStore.workItemToEdit = workItem;
        } catch (error) {
          console.error('Failed to fetch work item details:', error);
        }
      };
      fetchWorkItemDetails().then();
    }
  }, [snap.workItemToEdit.id]);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const service = new WorkItemsService();
      const isCreating = snap.workItemToEdit.id === 0;
      const response = isCreating
        ? await service.createWorkItem(workItemStore.workItemToEdit)
        : await service.updateWorkItem(workItemStore.workItemToEdit);

      if (isCreating) workItemStore.sortBy = "create_date";
      onActionClicked('save');
    } catch (e) {
      console.error('Error in handleSubmit:', e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = async () => {
    workItemStore.workItemToEdit = new WorkItem();
    onActionClicked('cancel');
  };

  const autoFill = async (workItem) => {
    //TODO
  };

  const renderCard = (Component, props = {}) => (
    <Card style={{ boxShadow: '0 1px 2px rgba(0,0,0,0.1)', marginBottom: '10px', borderRadius: '0px' }}>
      <Card.Body>
        <Component {...props} />
      </Card.Body>
    </Card>
  );

  if (isLoading) return <CustomLoadingDots />;
  if (error) { return <CustomError error={error} /> };
  if (!snap.workItemToEdit || !prefill) return null;

  return (
    <div style={{ zIndex: 1000 }}>
      {renderCard(WhereAndWhatTable, { skipAutoOpen: true, isEditing, createPrefill: autoFill })}
      {renderCard(WorkItemForm, { isEditing })}
      {renderCard(AssignedTo, { isEditing })}

      <div style={inspectionButtonContainerStyle(isMobile)}>
        {error && (
          <div style={{ display: "flex", color: "red", marginBottom: "10px", alignItems: "center" }}>
            <ErrorIcon sx={{ color: "red", marginRight: "2px" }} /> {error}</div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
            width: "100%"
        }}
        >
          <Button
            onClick={handleCancel}
            style={{ fontSize: "1.2rem" }}
          >
            Cancel
          </Button>
          <div
            style={{
              display: "flex",
              alignItems: "center"
            }}
          >
            <Button
              onClick={handleSubmit}
              style={{ fontSize: "1.2rem", marginRight: '30px' }}
            >
              {isSubmitting ? 'Submitting' : 'Submit'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InspectionWorkItem;
