import React, { useRef, useState } from "react";
import SagePrompter from "./SagePrompter";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  tellSageContainerStyles,
  vaButtonContainerStyles
} from "../../../../shared/utils/table-modifier-util/tableDataUtil";
import SageCaseCreate from "./case-creation-assist/SageCaseCreate";
import ChatComponent from "./chat-with-sage/ChatComponent";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import casesStore from "../../caseStore";
import { sageStore } from "../../../sage-virtual-assistant/sageStore";
import TellSageDialogActions from "../dynamic-form/components/TellSageDialogActions";

const TellSageModal = ({ onClose }) => {
  const isMobile = useMediaQuery(useTheme().breakpoints.only("xs"));
  const modalRef = useRef<any>(null);
  const { t } = useTranslation();
  const sageSnap = useSnapshot(sageStore);
  const casesSnap = useSnapshot(casesStore);
  const snap = useSnapshot(casesStore);
  const [tabValue, setTabValue] = useState(0);


  return (
    <div style={tellSageContainerStyles(isMobile, false)} ref={modalRef}>
      {tabValue === 0 && (
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ overflowY: "auto", flex: 1 }}>
            <SagePrompter
              sageResponse={snap.error === '' ? casesStore.formSections[snap.activeSection]?.sage_prompt : t('sage-error')}
              error={snap.error}
            />
            {!sageSnap.isTyping && !casesSnap.formLoading && (
              <Box
                sx={{
                  justifyContent: "center",
                  marginTop: "10px",
                  marginBottom: "200px",
                }}
              >
                  <SageCaseCreate
                    sections={casesStore.formSections}
                  />
              </Box>
            )}
          </div>
          <div style={vaButtonContainerStyles(snap.activeSection)}>
              <TellSageDialogActions sections={casesStore.formSections} onClose={onClose} />
          </div>
        </div>
      )}
      {/* CHAT Tab Content */}
      {tabValue === 1 && (
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <ChatComponent />
        </div>
      )}
    </div>
  );
};

export default TellSageModal;



