import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { GroupEntrySerializer } from "../../../models/serializers/groupEntrySerializer";
import { CaseTypeSerializer } from "../../../models/serializers/caseTypeSerializer";

export class CommandGetCaseTypes {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/cases/types/`;
  }

  async run(provider_id, department_id) {

    const url = this.getUrl()

    const params = {
      params: {
        provider: provider_id,
        department: department_id !== 0 ? department_id : undefined,
      }
    }

    const tracer = useTracer.getState()
    tracer.trace('Cases', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('Cases', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      tracer.trace('Cases', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new CaseTypeSerializer();
    const types = serializer.deserializeList(data)
    return types

  }

}

