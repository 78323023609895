import { useEffect, useState, CSSProperties } from "react";
import useAppStore from "../../../../appStore";
import { AnalyticsService } from "../../../../shared/services/analytics/analyticsService";
import { CloudTag } from "../../../../shared/models/cloudTag.model";
import Card from "@mui/material/Card";

// Simple helper to randomize array order
function shuffleArray<T>(arr: T[]): T[] {
  const array = [...arr];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const TagCloud = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { selectedLocation } = useAppStore();

  const [isLoading, setIsLoading] = useState(false);
  const [cloudData, setCloudData] = useState<CloudTag[]>([]);

  useEffect(() => {
    const fetchTagCloud = async () => {
      setIsLoading(true);
      try {
        const service = new AnalyticsService();
        const data = await service.getCloudTags(
          user.organization?.id,
          selectedLocation?.id,
          "inspections",
          3.5
        );
        setCloudData(shuffleArray(data)); // Randomize order as soon as we fetch
      } catch (error) {
        if (error instanceof Error) {
          console.log(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedLocation?.id !== 0) {
      fetchTagCloud();
    }
  }, [selectedLocation, user.organization?.id]);

  // Helper to map weight to a smaller font-size range
  const getFontSize = (value: number, minVal: number, maxVal: number) => {
    const minFontSize = 4;
    const maxFontSize = 16;

    if (maxVal === minVal) {
      // if all weights are identical
      return `${(minFontSize + maxFontSize) / 2}px`;
    }
    // Linear interpolation
    const size =
      minFontSize +
      ((value - minVal) * (maxFontSize - minFontSize)) / (maxVal - minVal);

    return `${size}px`;
  };

  // Compute min and max from the entire data set
  const values = cloudData.map((tag) => tag.weight);
  const minVal = Math.min(...values);
  const maxVal = Math.max(...values);

  return (
    <Card
      sx={{
        marginTop: '15px',
        marginBottom: '15px',
        boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
        border: '1px solid #c7c7c7',
        position: 'relative'
      }}
    >
      {isLoading && <p>Loading...</p>}

      {!isLoading && cloudData.length > 0 && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            minHeight: "300px", // to give the cloud room vertically
          }}
        >
          {cloudData.map((tag) => {
            const fontSize = getFontSize(tag.weight, minVal, maxVal);
            const tagStyle: CSSProperties = {
              fontSize,
              margin: "5px 10px",
              cursor: "pointer",
              userSelect: "none",
            };

            return (
              <span
                key={tag.id}
                style={tagStyle}
                title={`Type: ${tag.tag_type} | Count: ${tag.count} | Weight: ${tag.weight}`}
              >
                {tag.name}
              </span>
            );
          })}
        </div>
      )}

      {!isLoading && cloudData.length === 0 && (
        <p>No data available for the selected location.</p>
      )}
    </Card>
  );
};

export default TagCloud;
