import React from "react";
import ActionButton from "../../../../../shared/components/buttons/ActionButton";

const FormSectionFieldRow = (props) => {

  const {
    field,
    onEdit,
    onDelete
    } = props;

  return (
    <>
      <tr key={field?.id}>
        <td>
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-start flex-column">
              <a className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                {field?.name || ""}
              </a>
            </div>
          </div>
        </td>

          <>
            <td className="text-start">
              <div className="d-flex align-items-center">
                {field?.type || ""}
              </div>
            </td>
            <td className="text-start">
              <div className="d-flex align-items-center">
                {field?.data_source || ""}
              </div>
            </td>
          </>

        <td className="text-end">
          <div className="d-flex justify-content-end flex-shrink-0">
            <ActionButton
              iconType="pencil"
              onClick={(e) => {
                e.preventDefault();
                onEdit(field);
              }}
            />
            <ActionButton
              iconType="trash"
              onClick={(e) => {
                e.preventDefault();
                onDelete(field);
              }}
            />
          </div>
        </td>
      </tr>
    </>
  )
}

export default FormSectionFieldRow