import useAppStore from "../../../../../appStore";
import { useSnapshot } from "valtio/index";
import caseTypeStore from "../../case-types/caseTypeStore";
import React from "react";
import FormSectionRow from "./FormSectionRow";

const FormSectionTable = () => {
  const snap = useSnapshot(caseTypeStore);

  console.log(caseTypeStore.formToEdit)

  const handleSelect = (section) => {
    caseTypeStore.selectedFormSection = section;
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
          <tbody>
          {caseTypeStore.formToEdit?.form?.sections?.map((section) => (
            <FormSectionRow
              key={section.id}
              section={section}
              onSelect={handleSelect}
              isSelected={snap.selectedFormSection && snap.selectedFormSection.id === section.id}
            />
          ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default FormSectionTable;