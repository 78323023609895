import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Divider, Tabs, Tab, Snackbar, useTheme } from "@mui/material";
import useAppStore from "../../../../appStore";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import { CasesService } from "../../../../shared/services/cases/casesService";
import CaseCard from "../../../cases/components/case-list/components/mobile/CaseCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Case } from "../../../../shared/models/case.model";
import ItemDetails from "../user-work-items/components/components/ItemDetails";
import ComponentViewerSmall from "../../../../shared/components/page/modal/ComponentViewerSmall";
import { useTranslation } from "react-i18next";
import { AnalyticsService } from "../../../../shared/services/analytics/analyticsService";
import EditCase from "../../../cases/components/edit-case/EditCase";
import { useSnapshot } from "valtio";
import casesStore from "../../../cases/caseStore";
import NothingToldCard from "../../client-dashboard/things-i-told-sage/NothingToldCard";

const ThingsIveToldSage = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { selectedLocation, appImages } = useAppStore();
  const { t } = useTranslation();
  const service = new AnalyticsService();
  const [cases, setCases] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [tab, setTab] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const snap = useSnapshot(casesStore);

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true);
      try {
        const items= await service.getSuggestionItems(user.organization?.id, user?.employee.id, 'things_i_told_sage');
        setCases(items);
      } catch (error) {
        setError(error instanceof Error ? error.message : String(error));
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData().then();
  }, [selectedLocation, tab, showMore, user]);

  const handleShowMore = () => {
    setShowMore(true);
  };


  function renderCases() {
    return (
      <div>
        <div>
          {isLoading && (
            <div style={{ minHeight: "400px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <CustomLoadingDots />
            </div>
          )}

            {cases.map((item) => (
              <CaseCard
                key={item.id}
                item={item}
              />
            ))}

          {cases.length === 0 &&
            <NothingToldCard />
          }

        </div>

      </div>
    );
  }

  return (
  <div>

      {error && <CustomError error={error} />}

      {!error && (
        <>
          <div>
              <>
                {renderCases()}
                {snap.caseToEdit && snap.open && (
                  <EditCase
                    open={snap.open}
                  />
                )}
                {cases.length === 6 && !showMore &&
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row"
                  }}>
                    <Button sx={{ width: "100%", padding: "10px", marginBottom: "20px", fontSize: "1.1rem" }}
                            onClick={handleShowMore}>Show More
                      <KeyboardArrowDownIcon />
                    </Button>
                  </div>}
              </>

          </div>

        </>
      )}
    </div>
  );
};

export default ThingsIveToldSage;
