import React from 'react';
import ActionButton from '../../../../../shared/components/buttons/ActionButton';

const SuppliesRow = (props) => {
  const {
    item,
    onEdit,
    isMobileView,
    readOnly
  } = props;



  return (
    <>
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-start flex-column">
              <a className="text-gray-800 fw-bold mb-1 fs-6">
                {item.name}
              </a>
              <span className="text-gray-400 fw-semibold d-block fs-7"></span>
            </div>
          </div>
        </td>

        {!isMobileView &&
          <td>
            <div className="d-flex align-items-center">
              {item.description}
            </div>
          </td>
        }

        {!readOnly && (
          <td>
            <div className="d-flex justify-content-end flex-shrink-0">
              <ActionButton iconType="view" onClick={(e) => {
                e.preventDefault();
                onEdit(item);
              }} />
            </div>
          </td>
        )}

        {readOnly && item.media.length > 0 && (
          <td>
            <div className="d-flex justify-content-end flex-shrink-0">
              <ActionButton iconType="pdf" onClick={(e) => {
                e.preventDefault();
                onEdit(item);
              }} />
            </div>
          </td>
        )}
      </tr>

    </>
  );
}

export default SuppliesRow;

