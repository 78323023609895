import React, { useEffect } from "react";
import CaseTypeRow from "./CaseTypeRow";
import caseTypeStore from "../../caseTypeStore";
import { useSnapshot } from "valtio";
import useAppStore from "../../../../../../appStore";

const CaseTypesTable = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const {selectedLocation} = useAppStore();
  const snap = useSnapshot(caseTypeStore);

  useEffect(() => {
    caseTypeStore.handleFetchCaseTypes(user?.organization.id).then();
  }, [selectedLocation, user?.organization.id]);

  const handleSelect = (type) => {
    caseTypeStore.selectedCaseType = type;
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
          <tbody>
          {caseTypeStore.caseTypes.map((type) => (
            <CaseTypeRow
              key={type.id}
              type={type}
              onSelect={handleSelect}
              isSelected={snap.selectedCaseType && snap.selectedCaseType.id === type.id}
            />
          ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default CaseTypesTable;