import React, { useEffect, useState } from "react";
import { Button, Divider, Typography, useMediaQuery, useTheme } from "@mui/material";
import WhereAndWhatRow from "./WhereAndWhatRow";
import EditWhereAndWhat from "./edit-where-and-what/EditWhereAndWhat";
import { WhereAndWhatModel } from "../../../../../../shared/models/whereAndWhat.model";
import { useTranslation } from "react-i18next";
import { workItemStore } from "../../../../workItemStore";
import { useSnapshot } from "valtio";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { WorkItem } from "../../../../../../shared/models/workItem.model";

const WhereAndWhatTable = ({ skipAutoOpen, createPrefill, isEditing }) => {

  const snap = useSnapshot(workItemStore);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { t } = useTranslation();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [whereAndWhat, setWhereAndWhat] = useState<WhereAndWhatModel[]>([]);

  useEffect(() => {
    if (
      whereAndWhat.length === 0 &&
      snap.workItemToEdit.where_and_what.length !== 0
    ) {
      setWhereAndWhat(workItemStore.workItemToEdit.where_and_what);
    }
  }, [snap.workItemToEdit.where_and_what, whereAndWhat.length]);

  useEffect(() => {
    if (snap.workItemToEdit.id === 0 && !skipAutoOpen) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
    }
  }, [snap.workItemToEdit.id, skipAutoOpen]);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDelete = (index: number) => {
    const updatedWhereAndWhat = whereAndWhat.filter((_, i) => i !== index);
    setWhereAndWhat(updatedWhereAndWhat);
    workItemStore.workItemToEdit.where_and_what = updatedWhereAndWhat;
  };

  return (
    <Box>
      {/* Title + Dialog Component */}
      <Box marginBottom={2}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "gray.700" }}>
          {t("where-and-what")}
        </Typography>

        <EditWhereAndWhat
          open={isDialogOpen}
          onClose={handleCloseDialog}
          whereAndWhat={whereAndWhat}
          setWhereAndWhat={setWhereAndWhat}
          module={'workitems'}
          createPrefill={createPrefill}
        />

      </Box>

      {/* Table of "Where and What" Rows */}
      <TableContainer component={Paper} variant="outlined">
        <Table size="small" aria-label="where-and-what-table">
          <TableBody>
            {whereAndWhat.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography variant="body2">
                    {t("nothing-specified")}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              whereAndWhat.map((entry, index) => (
                <WhereAndWhatRow
                  key={index}
                  entry={entry}
                  isMobile={isMobile}
                  isEditing={isEditing}
                  onDelete={() => handleDelete(index)}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* "Add" Button (Only in edit mode) */}
      {isEditing && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenDialog}
          sx={{ mt: 2 }}
        >
          {t("add")} ...
        </Button>
      )}
    </Box>
  );
};

export default WhereAndWhatTable;
