import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Grid, Menu, MenuItem, IconButton, Box, Button } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import VisibilityIcon from '@mui/icons-material/Visibility';
import useAppStore from "../../../../../appStore";
import { formatWrittenDate } from "../../../../../shared/utils/table-modifier-util/tableDataUtil";
import { DateTime } from 'luxon';
import { useTranslation } from "react-i18next";
import JointInspectionModal from "./modal/JointInspectionModal";
import { JointInspectionSummaryModel } from "../../../../../shared/models/jointInspectionSummary.model";
import { AnalyticsService } from "../../../../../shared/services/analytics/analyticsService";

const JointInspectionTile = () => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const { selectedLocation, appImages, whiteLabelProps } = useAppStore();
  const service = new AnalyticsService();

  const [summary, setSummary] = useState<JointInspectionSummaryModel>(new JointInspectionSummaryModel());

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [preset, setPreset] = useState("");
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);

  useEffect(() => {
    const fetchSummary = async () => {
      setIsLoading(true);
      setError('');
      try {
        const jointSummary = await service.getJointSummary(
          user.organization,
          selectedLocation,
          fromDate,
          toDate
        );
        setSummary(jointSummary);
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchSummary().then();
  }, [user, selectedLocation.id, fromDate, toDate]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePresetChange = (value) => {
    setPreset(value);
    setAnchorEl(null);

    if (value === "all") {
      setFromDate(undefined);
      setToDate(undefined);
    } else {
      setFromDate(DateTime.now().minus({ days: Number(value) }));
      setToDate(DateTime.now());
    }
  };

  return (
    <Card
      sx={{
        marginTop: '15px',
        marginBottom: '15px',
        boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
        border: '1px solid #c7c7c7',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          backgroundColor: '#f1f1f1',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 16px',
          borderRadius: '4px 4px 0 0'
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#555' }}>
          {(preset === "" || preset === "all")
            ? t('all-time')
            : t('last-x-days', { count: Number(preset) })}
        </Typography>

        <Box display="flex" alignItems="center">
          <IconButton onClick={handleMenuOpen}>
            <DateRangeIcon sx={{ fontSize: '1.6rem', color: '#555' }} />
          </IconButton>
          <IconButton onClick={() => setOpen(true)}>
            <VisibilityIcon sx={{ fontSize: '1.6rem', color: '#555' }} />
          </IconButton>
        </Box>
      </Box>
      <CardContent>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => handlePresetChange("30")}>Last 30 Days</MenuItem>
          <MenuItem onClick={() => handlePresetChange("60")}>Last 60 Days</MenuItem>
          <MenuItem onClick={() => handlePresetChange("90")}>Last 90 Days</MenuItem>
          <MenuItem onClick={() => handlePresetChange("all")}>All time</MenuItem>
        </Menu>
        <Box sx={{ overflow: 'auto' }}>
          <table
            style={{
              width: '100%',
              borderCollapse: 'collapse'
            }}
          >
            <tbody>
            <tr
              style={{
                borderBottom: "1px solid #ddd",
                borderTop: "1px solid #ddd",
              }}
            >
              <td style={{ padding: "8px" }}>
                <div className={"d-flex flex-row align-items-center"}>
                  <img src={appImages.iconPassed} style={{ width: "25px", height: "25px", marginRight: "6px", marginBottom: '1px' }} />
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {"Average Score"}
                  </Typography>
                </div>
              </td>
              <td style={{ display: 'flex', padding: "8px", justifyContent: "end" }}>
                {summary.joint_inspection_score}
              </td>
            </tr>
            <tr
              style={{
                borderBottom: "1px solid #ddd",
              }}
            >
              <td style={{ padding: "8px" }}>
                <div className={"d-flex flex-row align-items-center"}>
                  <img src={appImages.iconWorkDone} style={{ width: "28px", height: "28px", marginRight: "5px", marginBottom: "2px" }} />
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {"Last Inspection"}
                  </Typography>
                </div>
              </td>
              <td style={{ display: 'flex', padding: "8px", justifyContent: 'end' }}>
                {summary.last_inspection_date ? formatWrittenDate(summary.last_inspection_date.toString()) : 'N/A'}
              </td>
            </tr>
            </tbody>
          </table>
        </Box>
        {open && (
          <JointInspectionModal
            open={open}
            onActionClicked={() => setOpen(false)}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default JointInspectionTile;

