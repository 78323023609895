import Card from "@mui/material/Card";
import React from "react";
import Box from "@mui/material/Box";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CardContent from "@mui/material/CardContent";

const NothingToldCard = () => {
  
  return (
    <Card
      sx={{
        marginTop: '15px',
        marginBottom: '50px',
        boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
        position: 'relative',
        border: '1px solid #c7c7c7',
      }}
    >
      <CardContent>
      <Box sx={{ overflow: 'auto', alignItems: 'center' }}>
              <div className={"d-flex flex-row align-items-center justify-content-center"}>
                <img src={toAbsoluteUrl('/white-labeling/verde/media/menu-icons/contactsIcon.png')} style={{ width: "35px", height: "35px", marginRight: "6px", marginBottom: '1px' }}  alt={'sage'}/>
                <Typography variant="body1" sx={{ fontWeight: "bold", fontSize: '1.15rem', color: '#555' }}>
                  {"You have no pending tell sage items"}
                </Typography>
              </div>
      </Box>
      </CardContent>
    </Card>
  );
};

export default NothingToldCard;