// casesStore.ts
import { proxy } from 'valtio';
import { Case } from '../../shared/models/case.model';
import { sageStore } from "../sage-virtual-assistant/sageStore";
import { CasesService } from "../../shared/services/cases/casesService";
import { CaseType } from "../../shared/models/caseType.model";
import { GroupEntry } from "../../shared/models/groupEntry.model";
import {
  caseTypeSection,
  confirmationSection,
  departmentSection
} from "./components/dynamic-form/components/createFormSections";
import { Section } from "../../shared/models/caseForm.model";

interface CasesStore {
  sageResponse: string;
  initialSteps: number;
  endingSteps: number;
  caseToEdit: Case;
  open: boolean;
  deleteOpen: boolean;
  isEditing: boolean;
  activeSection: number;
  sections: Section[];
  deepLinked: boolean;
  fetchError: string;
  error: string;
  postCreationModalOpen: boolean;
  availableDepartments: GroupEntry[];
  departmentsLoading: boolean;
  availableCaseTypes: CaseType[];
  caseTypesLoading: boolean;
  formSections: Section[];
  postCreationSections: Section[];
  formLoading: boolean;


  // Handler methods
  handleNewCase: () => void;
  handleViewCase: (item: Case, editing?: boolean) => void;
  handleCloseCaseModal: () => void;
  handleDeleteClicked: () => void;
  handleDeleteCase: (id: number) => Promise<void>;
  handleFetchDepts: (provider_id) => Promise<void>;
  handleFetchTypes: (provider_id, department_id) => Promise<void>;
  handleFetchForm: (type_id, manual) => Promise<void>;
  reset: () => void;
}

// Initialize the Valtio store
const casesStore = proxy<CasesStore>({
  // Initial state
  sageResponse: '',
  initialSteps: 2,
  endingSteps: 2,
  caseToEdit: new Case(),
  open: false,
  deleteOpen: false,
  isEditing: false,
  activeSection: 0,
  sections: [],
  deepLinked: false,
  fetchError: '',
  error: '',
  postCreationModalOpen: false,
  availableDepartments: [],
  departmentsLoading: false,
  availableCaseTypes: [],
  caseTypesLoading: false,
  formSections: [departmentSection(), caseTypeSection()],
  postCreationSections: [],
  formLoading: false,


  handleFetchDepts: async (provider_id) => {
    try {
      casesStore.departmentsLoading = true;
      const service = new CasesService();
      casesStore.availableDepartments = await service.getDepartments(provider_id);
    } catch (error) {
      if (error instanceof Error) {
        casesStore.fetchError = error.message;
      }
    } finally {
      casesStore.departmentsLoading = false;
    }
  },

  handleFetchTypes: async (provider_id, department_id) => {
    try {
      casesStore.caseTypesLoading = true;
      const service = new CasesService();
      casesStore.availableCaseTypes = await service.getCaseTypes(provider_id, department_id);
    } catch (error) {
      if (error instanceof Error) {
        casesStore.fetchError = error.message;
      }
    } finally {
      casesStore.caseTypesLoading = false;
    }
  },

  handleFetchForm: async (type_id, manual) => {
    try {
      casesStore.formLoading = true
      const service = new CasesService();
      const dynamicForm = await service.getDynamicForm(type_id);
      const fetchedSections = dynamicForm.form.sections || [];
      const normalSections = fetchedSections.filter(sec => !sec.show_after_creation);
      const summary = [confirmationSection()];
      const afterSections = fetchedSections.filter(sec => sec.show_after_creation);
      if (manual) {
        casesStore.formSections = [
          departmentSection(),
          caseTypeSection(),
          ...normalSections,
        ];
        casesStore.postCreationSections = afterSections;
      } else {
        casesStore.formSections = [
          departmentSection(),
          caseTypeSection(),
          ...normalSections,
          ...summary,
          ...afterSections
        ];
      }
    } catch (error) {
      console.error("Error fetching dynamic form:", error);
    } finally {
      casesStore.formLoading = false;
    }
  },


  handleNewCase: () => {
    casesStore.caseToEdit = new Case();
    casesStore.isEditing = true;
    casesStore.formSections = [departmentSection(), caseTypeSection()];
    casesStore.open = true;
  },

  handleViewCase: (item: Case, editing: boolean = false) => {
    casesStore.caseToEdit = item;
    casesStore.formSections = [departmentSection(), caseTypeSection()];
    if (item.case_type && item.case_type.id !== 0) {
      casesStore.handleFetchForm(item.case_type?.id, true);
    }
    casesStore.isEditing = editing;
    casesStore.open = true;
  },

  handleDeleteClicked: () => {
    casesStore.deleteOpen = true;
  },

  handleDeleteCase: async (id: number) => {
    try {
      const service = new CasesService();
      await service.deleteCase(id);
    } catch (error) {
      console.error(error);
    } finally {
      casesStore.caseToEdit = new Case();
      casesStore.deleteOpen = false;
      casesStore.open = false;
    }
  },

  handleCloseCaseModal: () => {
    casesStore.isEditing = false;
    casesStore.open = false;
    casesStore.deleteOpen = false;
    casesStore.caseToEdit = new Case();
    casesStore.formSections = [departmentSection(), caseTypeSection()];
  },

  reset: () => {
    casesStore.sageResponse = '';
    casesStore.caseToEdit = new Case();
    sageStore.isTyping = true;
    casesStore.activeSection = 0;
    casesStore.sections = [];
    casesStore.isEditing = false;
    casesStore.open = false;
    casesStore.deleteOpen = false;
    casesStore.formSections = [departmentSection(), caseTypeSection()];
  },
});

export default casesStore;


