import httpClient from "../../../utils/http-clients/djangoHttpClient";
import { CloudTagSerializer } from "../../../models/serializers/cloudTagSerializer";

export class CommandGetCloudTags {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {
    return `${this.SERVICE}/analytics/tags/`;
  }


  async run(provider_id, location_id, entity_type_string, score_lte) {
    const url = this.getUrl();

    const params = {
      params: {
        provider: provider_id,
        location: location_id,
        entity_type: entity_type_string,
        score_lte: score_lte
      }
    }

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  deserialize(data) {
    const serializer = new CloudTagSerializer();
    return serializer.deserializeList(data)
  }

}

