import React, { useState } from "react";
import {
  AppBar, Button,
  Dialog, Divider,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModalTransition from "../../../../../shared/components/page/transitions/ModalTransition";
import ModalFooterSubmitProgress from "../../../../../shared/components/buttons/ModalFooterSubmitProgress";
import ModalFooterActions from "../../../../../shared/components/buttons/ModalFooterActions";
import { useSnapshot } from 'valtio';
import caseTypeStore from "../../case-types/caseTypeStore";
import FormSectionTable from "./FormSectionTable";
import EditFormSection from "./EditFormSection";
import { Section } from "../../../../../shared/models/caseForm.model";

const EditForm = (props) => {
  const { open, onClose, onSave } = props;
  const snap = useSnapshot(caseTypeStore);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAddSection = () => {
    caseTypeStore.selectedFormSection = new Section();
  };

  const handleRemoveSection = (sectionId) => {

  };

  const handleSave = async () => {

  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={ModalTransition}
      PaperProps={{ style: { overflowX: "hidden", backgroundColor: "#f9f9f9" } }}
    >
      <AppBar sx={{ position: "fixed", top: 0, left: 0, right: 0, backgroundColor: "#444" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>


      <div className="card mt-15 p-5" style={{minHeight: "100vh"}}>
        <div className="card-body d-flex flex-row" style={{ alignItems: 'flex-start' }}>

          {/* Left */}
          <div className="d-flex flex-column flex-row-auto w-300px" style={{ minHeight: '600px', overflow: 'auto' }}>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h3>Sections</h3>
              <Button variant="contained" color="primary" onClick={handleAddSection}>
                + Section
              </Button>
            </div>
            <FormSectionTable />
          </div>

          <Divider orientation="vertical" flexItem style={{ backgroundColor: 'black', marginLeft: '15px' }} />

          {/* Right */}
          <div className="d-flex flex-column flex-row-fluid ms-5">
            {snap.selectedFormSection !== null ? (
              <>
                <EditFormSection />
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center"
                   style={{ flex: 1, textAlign: "center", padding: "20px", marginTop: "50px" }}>
                <div>
                  <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }}>
                    No Section Selected.
                  </p>
                  <p>Select a type from the left panel or add a new one.</p>
                  <Button color="primary" onClick={handleAddSection} style={{ height: '35px' }}>
                    + Section
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>


      {isSubmitting ? <ModalFooterSubmitProgress /> : <ModalFooterActions onSave={handleSave} onClose={onClose} text={'SAVE FORM'} />}
    </Dialog>
  );
};

export default EditForm;