import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TextFieldButton = styled(Button)(({ theme }) => ({
  width: '10%',
  height: '45px',
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  borderTopRightRadius: '8px',
  borderBottomRightRadius: '8px',
  color: '#555',
  backgroundColor: '#e9e9e9',
  boxShadow: 'none',
  border: '1px solid lightgray',
  '&:hover': {
    backgroundColor: '#d3d3d3',
  },
}));
