import React, { useEffect, useState, useCallback } from 'react';
import {
  Dialog, DialogContent, DialogActions, Button, AppBar, IconButton, Typography,
  List, ListItem, ListItemText, CircularProgress, TextField, Box, Chip
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import useAppStore from '../../../../../../../../appStore';
import { LocationModel } from '../../../../../../../../shared/models/location.model';
import { CasesService } from "../../../../../../../../shared/services/cases/casesService";
import { GroupEntry } from "../../../../../../../../shared/models/groupEntry.model";
import { EmployeeService } from "../../../../../../../../shared/services/employee/employeeService";

const DepartmentSelectionForm = ({ employee_id, activeDepartments, setActiveDepartments, open, onClose }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const service = new EmployeeService();
  const [departmentList, setDepartmentList] = useState<GroupEntry[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchDepartments = useCallback(async () => {
    setIsLoading(true);
    try {
      const service = new CasesService();
      const depts = await service.getDepartments(user?.organization.id);
      setDepartmentList(depts);
    } catch (error) {
      console.error('Error fetching departments:', error);
    } finally {
      setIsLoading(false);
    }
  }, [user.organization.id]);

  useEffect(() => {
    fetchDepartments().then();
  }, [fetchDepartments]);

  const toggleDepartmentSelection = useCallback((entry: GroupEntry) => {
    setActiveDepartments((current) => {
      const isSelected = !!current.find(e => e.id === entry.id);
      return isSelected
        ? current.filter(e => e.id !== entry.id)
        : [...current, entry];
    });
  }, [setActiveDepartments]);

  const handleSave = async () => {
    try {
      await Promise.all(activeDepartments.map(async (department) => {
        await service.linkDepartment(employee_id, department.id);
      }));

      console.log('Departments successfully linked');
      onClose();
    } catch (error) {
      console.error('Error saving departments:', error);
      setError('Failed to save departments. Please try again.');
    } finally {
      onClose();
    }
  };


  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Department Selection
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        {isLoading ? <CircularProgress /> : (
          <>
            <List>
              {departmentList.map(location => (
                <ListItem
                  key={location.id}
                  button
                  selected={!!activeDepartments.find(e => e.id === location.id)}
                  onClick={() => toggleDepartmentSelection(location)}
                >
                  <ListItemText primary={location.name} />
                </ListItem>
              ))}
            </List>
          </>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DepartmentSelectionForm;

