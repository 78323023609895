import React from "react";
import { useSnapshot } from "valtio";
import caseTypeStore from "../../../caseTypeStore";
import FormSelector from "./FormSelector";
import { Button } from "@mui/material";

const FormLink = () => {
  const snap = useSnapshot(caseTypeStore);

  const handleChangeForm = () => {
    caseTypeStore.formSelectOpen = true;
  };

  return (
    <>
      <span className="text-gray-700" style={{ fontSize: "0.9rem" }}>Form</span>
      <div
        style={{
          border: "1px solid lightgray",
          borderRadius: "8px",
          padding: "16px",
          position: "relative",
          backgroundColor: "#f9f9f9",
        }}
      >
        {/* Change Form button in the top-right corner */}
        <Button
          onClick={handleChangeForm}
          style={{
            position: "absolute",
            top: "8px",
            right: "8px",
            border: "none",
            padding: "6px 12px",
            cursor: "pointer",
          }}
        >
        CHANGE
        </Button>

        {/* Summary of the current form (dummy data) */}
        <div>
          <div style={{ marginTop: "8px" }}>
            <p style={{ margin: 0, color: "#555" }}>
              <strong>Name:</strong> Default (Hardcoded Dummy)
            </p>
            <p style={{ margin: 0, color: "#555" }}>
              <strong>Sections:</strong>
            </p>
            <ul style={{ paddingLeft: "16px", marginTop: "4px", color: "#555" }}>
              <li>Details: 3 fields</li>
              <li>Specifics: 4 fields</li>
            </ul>
          </div>
        </div>
      </div>

      {snap.formSelectOpen &&
        <FormSelector />
      }
    </>

  );
};

export default FormLink;
