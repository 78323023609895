import { proxy } from "valtio";
import { ServiceLogArea } from "../../../../shared/models/serviceLogArea";
import { Entity } from "../../../../shared/models/entity.model";
import { EntityService } from "../../../../shared/services/entities/entityService";
import { EntityTypeService } from "../../../../shared/services/entity-types/entityTypeService";
import { EntityType } from "../../../../shared/models/entityType.model";

interface ServiceLogStore {
  selectedArea: ServiceLogArea;
  filteredBuilding: Entity | null;
  filteredFloor: Entity | null;
  filteredType: EntityType | null;
  buildings: Entity[];
  floors: Entity[];
  types: Entity[];
  buildingsLoading: boolean;
  floorsLoading: boolean;
  typesLoading: boolean;
  fetchError: string;

  handleFetchBuildings: (location_id) => Promise<void>;
  handleFetchFloors: (location_id, building_id) => Promise<void>;
  handleFetchTypes: (location_id, floor_id) => Promise<void>;
  handleReset: () => void;

}

export const serviceLogStore = proxy<ServiceLogStore>({
  selectedArea: new ServiceLogArea(),
  filteredBuilding: null,
  filteredFloor: null,
  filteredType: null,
  buildings: [],
  floors: [],
  types: [],
  buildingsLoading: false,
  floorsLoading: false,
  typesLoading: false,
  fetchError: '',

  handleFetchBuildings: async (location_id) => {
    try {
      serviceLogStore.buildingsLoading = true;
      const service = new EntityService();
      serviceLogStore.buildings = await service.getEntities(location_id);
    } catch (error) {
      if (error instanceof Error) {
        serviceLogStore.fetchError = error.message;
      }
    } finally {
      serviceLogStore.buildingsLoading = false;
    }
  },

  handleFetchFloors: async (location_id, building_id) => {
    try {
      serviceLogStore.floorsLoading = true;
      const service = new EntityService();
      serviceLogStore.floors = await service.getEntities(location_id, building_id);
    } catch (error) {
      if (error instanceof Error) {
        serviceLogStore.fetchError = error.message;
      }
    } finally {
      serviceLogStore.floorsLoading = false;
    }
  },

  handleFetchTypes: async (location_id, floor_id) => {
    try {
      serviceLogStore.typesLoading = true;
      const service = new EntityTypeService();
      serviceLogStore.types = await service.getEntityTypes(location_id, 'Place', floor_id);
    } catch (error) {
      if (error instanceof Error) {
        serviceLogStore.fetchError = error.message;
      }
    } finally {
      serviceLogStore.typesLoading = false;
    }
  },

  handleReset: () => {
    serviceLogStore.selectedArea = new ServiceLogArea();
    serviceLogStore.filteredBuilding = null;
    serviceLogStore.filteredFloor = null;
    serviceLogStore.filteredType = null;
    serviceLogStore.fetchError = '';
  },



});