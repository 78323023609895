import React, { useEffect, useState } from "react";
import {
  Typography,
  List,
  ListItem,
  CircularProgress,
  TextField,
  Box,
  Chip, Checkbox
} from "@mui/material";
import { Employee } from "../../../../../../shared/models/employee.model";
import { TextFieldButton } from "../../../../../../shared/components/buttons/TextFieldButton";
import { KeyboardReturn } from "@mui/icons-material";
import EmployeeDetailRow from "../../../../../../shared/components/page/tables/EmployeeDetailRow";
import casesStore from "../../../../caseStore";
import useAppStore from "../../../../../../appStore";
import { EmployeeService } from "../../../../../../shared/services/employee/employeeService";
import CancelIcon from "@mui/icons-material/Cancel";

type CaseEmployeeFieldProps = {
  fieldName?: string;
};

const CaseEmployeeField: React.FC<CaseEmployeeFieldProps> = ({ fieldName = '' }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);

  const initialSelectedEmployees = (casesStore.caseToEdit[fieldName] ?? []) as Employee[];
  const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>(initialSelectedEmployees);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [employeeSearchResult, setEmployeeSearchResult] = useState<Employee[]>([]);
  const [searchFor, setSearchFor] = useState('');

  // on search, fetch employees
  useEffect(() => {
    const fetchEmployees = async () => {
      setIsLoading(true);
      try {
        const service = new EmployeeService();
        const employees = await service.searchEmployees(user.organization.id, searchFor);
        setEmployeeSearchResult(employees);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (searchFor !== '') {
      void fetchEmployees();
    }
  }, [searchFor, user.organization.id]);

  const handleSearch = () => {
    setSearchFor(searchQuery);
  };


  const handleRemoveEmployee = (employeeId) => {
    const updatedSelectedEmployees = selectedEmployees.filter(e => e.id !== employeeId);
    setSelectedEmployees(updatedSelectedEmployees);
    casesStore.caseToEdit[fieldName] = updatedSelectedEmployees;
  };

  const handleListItemClick = (employee: Employee) => {
    const isSelected = selectedEmployees.some((e) => e.id === employee.id);
    let updatedSelectedEmployees: Employee[];

    if (isSelected) {
      updatedSelectedEmployees = selectedEmployees.filter(e => e.id !== employee.id);
    } else {
      updatedSelectedEmployees = [...selectedEmployees, employee];
    }

    setSelectedEmployees(updatedSelectedEmployees);
    casesStore.caseToEdit[fieldName] = updatedSelectedEmployees;

    setSearchFor('');
    setSearchQuery('');
    setEmployeeSearchResult([]);
  };

  const renderSelectedEmployees = () => {
    return selectedEmployees.map(employee => (
      <Chip
        key={employee.id}
        label={`${employee.person.first_name} ${employee.person.last_name}`}
        onDelete={() => handleRemoveEmployee(employee.id)}
        deleteIcon={<CancelIcon />}
        sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
      />
    ));
  };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {selectedEmployees.length > 0 && (
            <Box sx={{ flexGrow: 1, paddingTop: '10px', paddingBottom: '10px' }}>
              {renderSelectedEmployees()}
            </Box>
          )}
        </Box>
      </Box>

      <div style={{ display: 'flex', flexDirection: 'column'}}>
        <div className="d-flex flex-row">
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSearch();
            }}
            sx={{
              width: '90%',
              '& .MuiOutlinedInput-root': {
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                height: '45px',
                '& .MuiInputBase-input': {
                  height: '100%',
                  padding: '16.5px 14px',
                },
              },
            }}
          />
          <TextFieldButton onClick={handleSearch}>
            <KeyboardReturn />
          </TextFieldButton>
        </div>

        <div style={{ flex: 1, overflow: 'auto' }}>
          {employeeSearchResult.length > 0 && (
            <List>
              {isLoading ? (
                <CircularProgress />
              ) : (
                employeeSearchResult.map((employee) => {
                  const isSelected = !!selectedEmployees.find((e) => e.id === employee.id);
                  return (
                    <ListItem
                      key={employee.id}
                      button
                      selected={isSelected}
                      onClick={() => handleListItemClick(employee)}
                    >
                      <Checkbox
                        edge="start"
                        checked={isSelected}
                        tabIndex={-1}
                        disableRipple
                      />
                      {/* Employee details */}
                      <EmployeeDetailRow employee={employee} />
                    </ListItem>
                  );
                })
              )}
            </List>
          )}
        </div>
      </div>

      {error && <Typography color="error">{error}</Typography>}
    </>
  );
};

export default CaseEmployeeField;
