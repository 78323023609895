import { Button } from "@mui/material";
import React, { useState } from "react";
import casesStore from "../../../caseStore";
import { SageError } from "../../../../../shared/models/sageError.model";
import { useSnapshot } from "valtio";
import { CasesService } from "../../../../../shared/services/cases/casesService";

const TellSageDialogActions = ({ sections, onClose }) => {
  const snap = useSnapshot(casesStore);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationError, setValidationError] = useState('');
  const service = new CasesService();

  const validateRequiredFields = () => {
    const currentSection = sections[snap.activeSection];
    if (!currentSection || !currentSection.fields) return true;

    const invalidFields = currentSection.fields.filter((field) => {
      if (!field.is_required) {
        return false;
      }

      if (field.data_source.includes('$model')) {
        const modelKey = field.data_source.replace('$model.', '');
        return !casesStore.caseToEdit[modelKey];
      } else {
        return !casesStore.caseToEdit.form_data[field.data_source];
      }
    });

    if (invalidFields.length > 0) {
      setValidationError(`Please fill out all required fields:
        ${invalidFields.map((f) => f.name || "Unnamed Field").join(", ")}`);
      return false;
    }

    setValidationError('')
    return true;
  };

  const handleNextStep = () => {
    casesStore.error = "";
    if (!validateRequiredFields()) return;
    casesStore.activeSection++;
  };

  const handlePrevStep = () => {
    casesStore.error = "";
    casesStore.activeSection--;
  };

  const handleSubmit = async () => {

    setIsSubmitting(true);
    try {
      casesStore.error = "";
      casesStore.caseToEdit = await service.createCase(casesStore.caseToEdit);
      if (snap.activeSection !== sections.length - 1) {
        handleNextStep();
      } else {
        onClose();
      }
    } catch (error) {
      casesStore.error = error instanceof SageError ? error.message : JSON.stringify(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (snap.activeSection > sections.length - 1) {
    return null;
  }

  return (
    <>
      {/* Display an error message if it exists */}
      {validationError && (
        <div style={{ color: "red", marginBottom: "10px" }}>
          {validationError}
        </div>
      )}

      {snap.activeSection > 0 &&
        !sections[snap.activeSection].show_after_creation && (
          <Button
            onClick={handlePrevStep}
            style={{
              marginBottom: "20px",
              marginRight: "20px",
              fontSize: "1.2rem",
            }}
          >
            Prev
          </Button>
        )}

      {/*
        Example: if your condition to show the Next button is:
        snap.activeSection > 1 && sections[snap.activeSection].name !== 'Summary' && ...
        then replace 'handleNextStep' with our new validated version
      */}
      {snap.activeSection > 1 &&
        sections[snap.activeSection].name !== "Summary" &&
        sections[snap.activeSection].name !== "Status" &&
        snap.activeSection !== sections.length - 1 && (
          <Button
            onClick={handleNextStep}
            disabled={snap.formLoading}
            style={{ marginBottom: "20px", fontSize: "1.2rem" }}
          >
            Next
          </Button>
        )}

      {sections[snap.activeSection].name === "Summary" &&
        (isSubmitting ? (
          <Button
            onClick={undefined}
            style={{ marginBottom: "20px", fontSize: "1.25rem", color: "darkgreen" }}
          >
            Submitting...
          </Button>
        ) : (
          <Button
            onClick={handleSubmit}
            style={{ marginBottom: "20px", fontSize: "1.2rem" }}
          >
            Submit
          </Button>
        ))}

      {snap.activeSection > 1 &&
        snap.activeSection === sections.length - 1 &&
        sections[snap.activeSection].name !== "Summary" && (
          <>
            <Button
              onClick={onClose}
              style={{ marginBottom: "20px", fontSize: "1.2rem" }}
            >
              Close
            </Button>
          </>
        )}
    </>
  );
};

export default TellSageDialogActions;
