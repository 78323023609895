import React, { useCallback, useEffect, useState } from "react";
import useAppStore from "../../../../appStore";
import PageHeader from "../../../../shared/components/page/headers/PageHeader";
import { ServiceLogArea } from "../../../../shared/models/serviceLogArea";
import { ServiceLogService } from "../../../../shared/services/service-logs/serviceLogService";
import ActionButton from "../../../../shared/components/buttons/ActionButton";
import DuotoneCategory from "../../../../shared/components/page/row-icons/DuotoneCategory";
import ServiceLogViewer from "./components/ServiceLogViewer";
import {
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Box, Typography, CircularProgress } from '@mui/material';
import ManualLogEntry from "./components/ManualLogEntry";
import { useTranslation } from "react-i18next";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import WhereAndWhatFilter from "./components/WhereAndWhatFilter";
import { useSnapshot } from "valtio";
import { serviceLogStore } from "./serviceLogStore";
import CancelIcon from "@mui/icons-material/Cancel";

const LocationServiceLog = () => {
  const logService = new ServiceLogService();
  const { selectedLocation, appImages } = useAppStore();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const [log, setLog] = useState<ServiceLogArea[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logsOpen, setLogsOpen] = useState<boolean>(false);
  const [areaIdToView, setAreaIdToView] = useState<number>(0);
  const [selectorOpen, setSelectorOpen] = useState<boolean>(false);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const snap = useSnapshot(serviceLogStore);


  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const locations = await logService.getLogList(selectedLocation.id, snap.filteredFloor?.id || 0, snap.filteredType?.id || 0, undefined);
        setLog(locations);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [snap.filteredFloor, snap.filteredType, selectedLocation.id]);

  const handleCloseLogs = () => {
    setLogsOpen(false);
    setAreaIdToView(0);
  }

  const handleCloseSelector = () => {
    setSelectorOpen(false);
  }

  const handleOpenFilter = () => {
    setFilterOpen(true);
  }

  const handleCloseFilter = () => {
    setFilterOpen(false);
  }

  const removeSpaceFilter = () => {
    serviceLogStore.filteredBuilding = null;
    serviceLogStore.filteredFloor = null;
    serviceLogStore.filteredType = null;
  }

  return (
    <>
      <div>
        {snap.filteredFloor && snap.filteredBuilding && snap.filteredType &&
          <div style={{marginBottom: '10px'}}>
            <Chip
              key={snap.filteredFloor?.id}
              label={snap.filteredFloor.name + " / " + snap.filteredType.name}
              onDelete={removeSpaceFilter}
              deleteIcon={<CancelIcon />}
              sx={{ m: 0.5, fontSize: "1rem", backgroundColor: "#e2e2e2" }}
            />
          </div>
        }
      </div>
      <div className={`card m-0 p-8`} style={{ border: "2px solid #e8e8e8", minHeight: '700px' }}>
        <PageHeader title={t('service-logs')} showAction={true} actionButtonName={t('entry')} onActionClicked={() => setSelectorOpen(true)} showSecondary={true} secondaryButtonName={<FilterAltIcon />} onSecondaryClicked={handleOpenFilter}  />
        <div style={{marginTop: '15px'}}>
          <div>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : log.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
            <img src={appImages.iconNoData} alt="No log entries"
                 style={{ width: "150px", marginBottom: "10px", marginTop: "100px" }} />
            <Typography variant="h6" sx={{ color: "#777" }}>
              No log entries for selected filters!
            </Typography>
          </Box>
        ) : (
          <div className="table-responsive">
            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
              {!isMobile && (
                <thead>
                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                  <th className="p-0 pb-3 min-w-150px text-start">AREA</th>
                  <th className="p-0 pb-3 min-w-50px text-start px-3">IN SERVICE</th>
                  <th className="p-0 pb-3 min-w-50px text-start px-3">INSPECTION FREQUENCY</th>
                  <th className="p-0 pb-3 min-w-50px text-end">ACTION</th>
                </tr>
                </thead>
              )}

              <tbody>
              {log.map((area, idx) => (
                <tr key={area?.id}>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="me-3">
                        <DuotoneCategory />
                      </div>
                      <div className="d-flex justify-content-start flex-column">
                        <a className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                          {area?.entity?.name || ""}
                        </a>
                        {!isMobile &&
                          <span className="text-gray-400 fw-semibold d-block fs-7">
                                {`ID: ${area?.id}`}
                              </span>
                        }
                      </div>
                    </div>
                  </td>

                  {!isMobile && (
                    <>
                      <td className="text-start">
                        <div className="d-flex align-items-center">
                          {area?.entity?.in_service ? "Yes" : "No"}
                        </div>
                      </td>
                      <td className="text-start">
                        <div className="d-flex align-items-center">
                          {`Every ${area?.entity?.inspection_frequency} days` || ""}
                        </div>
                      </td>
                    </>
                  )}

                  <td className="text-end">
                    <div className="d-flex justify-content-end flex-shrink-0">
                      <ActionButton
                        iconType="view"
                        onClick={(e) => {
                          setAreaIdToView(area?.id || 0);
                          setLogsOpen(true);
                          e.preventDefault();
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
        {logsOpen && (
            <ServiceLogViewer open={logsOpen} onActionClicked={handleCloseLogs} id={areaIdToView} />
        )}
        {selectorOpen && (
          <ManualLogEntry open={selectorOpen} onClose={handleCloseSelector} />
        )}
        {filterOpen && (
          <WhereAndWhatFilter open={filterOpen} onClose={handleCloseFilter} />
        )}
      </div>
    </>
  );
};

export default LocationServiceLog;

