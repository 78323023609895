import { Box, Button, TextField } from "@mui/material";
import React from "react";
import caseTypeStore from "../../caseTypeStore";
import DepartmentLink from "./components/DepartmentLink";
import FormLink from "./components/FormLink";
import InfoIcon from '@mui/icons-material/Info';
import { useSnapshot } from "valtio";
import useAppStore from "../../../../../../appStore";

const EditCaseType = () => {

  const snap = useSnapshot(caseTypeStore);
  const {whiteLabelProps} = useAppStore();

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (caseTypeStore.selectedCaseType) {
      caseTypeStore.selectedCaseType.name = value;
    }
  };

  const handleSaveCaseType = () => {
    //TODO case type save
  };

  return (
    <Box className={"mb-5"} style={{ position: "sticky", top: 0, zIndex: 100 }}>
      {snap.selectedCaseType && snap.selectedCaseType.id === 0 ? (
          <div className="d-flex justify-content-between align-items-center mb-4" style={{marginLeft: '15px'}}>
            <h3 style={{fontSize: '1.1rem', fontWeight: 200, fontStyle: 'italic', color: whiteLabelProps.primary_color}}>
              <InfoIcon sx={{fontSize: '2rem', color: whiteLabelProps.primary_color, marginRight: '5px'}} />
              New Case Type: Fill out details and link a form to create new type
            </h3>
            <Button variant="contained" color="primary" onClick={handleSaveCaseType} disabled={snap.selectedCaseType?.name === ''}>
              Save
            </Button>
          </div>
      ) : (
        <div className="d-flex justify-content-between align-items-center mb-4" style={{marginLeft: '15px'}}>
          <h3>Edit Type</h3>
          <Button variant="contained" color="primary" onClick={handleSaveCaseType}>
            Save
          </Button>
        </div>
      )}
      <span className="text-gray-700" style={{ fontSize: "0.9rem", marginLeft: "15px" }}>{"Name"}</span>
      <div className="mb-5 d-flex flex-row align-items-center">
        <div className="input-group">
          <TextField
            label={""}
            required
            name="Case Type Name"
            value={caseTypeStore.selectedCaseType?.name || ''}
            onChange={handleNameChange}
            sx={{
              width: "99%",
              marginLeft: "15px",
              '& .MuiOutlinedInput-root': {
                height: '45px',
                '& .MuiInputBase-input': {
                  height: '100%',
                  padding: '16.5px 14px',
                },
              },
            }}
          />
        </div>
      </div>
      <div style={{ marginLeft: '15px' }}>
        <DepartmentLink />
        <FormLink />
      </div>

    </Box>
  )
}

export default EditCaseType