import { CommandGetCases } from "./commands/cmdGetCases";
import { CommandGetDepartments } from "./commands/cmdGetDepartments";
import { CommandGetCaseTypes } from "./commands/cmdGetCaseTypes";
import { CommandCreateCase } from "./commands/cmdCreateCase";
import { CommandUpdateCase } from "./commands/cmdUpdateCase";
import { CommandGetCaseTypeCounts } from "./commands/cmdGetStatusCounts";
import { CommandDeleteCase } from "./commands/cmdDeleteCase";
import { CommandGetDetailCase } from "./commands/cmdGetDetailCase";
import { CommandGetCreatedCases } from "./commands/cmdGetCreatedCases";
import { CommandLookupCaseType } from "./commands/cmdLookupCaseType";
import { CommandGetDynamicForm } from "./commands/cmdGetDynamicForm";

export class CasesService {

  async getCases(provider_id: number, employee_id: number | null, location_id: number | undefined, status?: string | null, case_type?: number | null, limit?: number | null, offset?: number | null) {
    const cmd = new CommandGetCases();
    return await cmd.run({
      provider_id,
      employee_id,
      location_id,
      status,
      case_type,
      limit,
      offset
    });
  }

  async getCreatedCases(provider_id: number | undefined, employee_id: number | undefined) {
    const cmd = new CommandGetCreatedCases();
    return await cmd.run(provider_id, employee_id);
  }

  async getDetailCase(itemId) {
    const cmd = new CommandGetDetailCase();
    return await cmd.run(itemId)
  }

  async getDepartments(provider_id) {
    const cmd = new CommandGetDepartments();
    return await cmd.run(provider_id)
  }

  async getCaseTypes(provider_id, department_id) {
    const cmd = new CommandGetCaseTypes();
    return await cmd.run(provider_id, department_id)
  }

  async lookupCaseType(org_id, search) {
    const cmd = new CommandLookupCaseType();
    return await cmd.run(org_id, search)
  }

  async getDynamicForm(type_id) {
    const cmd = new CommandGetDynamicForm();
    return await cmd.run(type_id)
  }

  async getCaseTypeCounts(provider_id, location_id) {
    const cmd = new CommandGetCaseTypeCounts();
    return await cmd.run(provider_id, location_id);
  }

  async createCase(item) {
    const cmd = new CommandCreateCase()
    return await cmd.run(item)
  }

  async updateCase(item) {
    const cmd = new CommandUpdateCase();
    return await cmd.run(item);
  }

  async deleteCase(itemId) {
    const cmd = new CommandDeleteCase();
    return await cmd.run(itemId);
  }

}
