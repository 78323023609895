import React, { useState } from "react";
import { AppBar, Button, Dialog, IconButton, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Toolbar from "@mui/material/Toolbar";
import EditInspectedEntity from "./components/edit-inspected-entity/EditInspectedEntity";
import { InspectionService } from "../../../../shared/services/inspections/inspectionService";
import InspectionSummary from "./components/inspection-summary/InspectionSummary";
import JointInspectedByCard from "./components/joint-inspection/JointInspectedByCard";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import InspectedEntitiesCardList from "./components/inspected-entities-list/mobile-view/InspectedEntitiesCardList";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { ConfirmationDialog } from "../../../../shared/components/page/popup-dialog/ConfirmationDialog";
import InspectionDatePicker from "./components/inspection-custom-date/InspectionDatePicker";
import useAppStore from "../../../../appStore";
import ModalFooterActions from "../../../../shared/components/buttons/ModalFooterActions";
import DivToPdf from "./components/inspection-pdf-print/DivToPdf";
import { printStyles } from "../../../../shared/utils/table-modifier-util/tableDataUtil";
import InspectedByCard from "./components/inspection-pdf-print/InspectedByCard";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { inspectionsStore } from "../../inspectionsStore";
import CreateInspectedEntity from "./components/create-inspected-entity/CreateInspectedEntity";
import { inspectedEntityStore } from "../../inspectedEntityStore";
import { PermissionLevels } from "../../../../shared/models/userPermissions.model";


const EditAreaInspectionPage = () => {

    const {t} = useTranslation();
    const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
    const inspectionSnap = useSnapshot(inspectionsStore);
    const inspectedEntitySnap = useSnapshot(inspectedEntityStore);
    const {toggleMasterRefresh} = useAppStore()
    const dateChangePermission = user?.permissions?.hasAccess( 'Change Inspection Date') || false;
    const canEdit = user?.permissions?.hasAccess( 'Inspections', PermissionLevels.UPDATE) || false;
    const canDelete = user?.permissions?.hasAccess( 'Inspections', PermissionLevels.DELETE) || false;
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [totalSpaces, setTotalSpaces] = useState<number>(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only('xs'));


    const handleClose = () => {
        inspectedEntityStore.creating = false;
        inspectionsStore.open = false;
        inspectionsStore.inspectionToEdit = null;
    }

    const handleSave = async () => {
        try {
            const service = new InspectionService();
            await service.updateInspection(inspectionsStore.inspectionToEdit);
            toggleMasterRefresh();
            inspectedEntityStore.creating = false;
            inspectedEntityStore.editing = false;
            handleClose();
        } catch (error) {
            console.error('Error saving inspection:', error);
        }
    };

    const handleDeleteClicked = () => {
        setIsConfirmDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        handleClose();
        if (inspectionSnap.inspectionToEdit?.id) {
            await inspectionsStore.handleDeleteInspection(inspectionSnap.inspectionToEdit.id)
            toggleMasterRefresh();
        }
    }

    return (
      <>
          <>
              <style>{printStyles}</style>
              <Dialog fullScreen open={inspectionSnap.open} onClose={handleClose} TransitionComponent={ModalTransition}
                      PaperProps={{
                          style: {
                              overflowX: 'hidden',
                              overflowY: 'auto',
                          },
                      }}>
                  <AppBar sx={{ position: 'fixed', top: 0, left: 0, right: 0 }}>
                      <Toolbar>
                          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                              <CloseIcon />
                          </IconButton>

                          {/* Spacer to push the following buttons to the right */}
                          <Box sx={{ flexGrow: 1 }} />

                          {!isMobile && !inspectionSnap.isEditing && (
                            <DivToPdf contentId={"modal-content"} />
                          )}
                          {!inspectionSnap.isEditing && canEdit && (
                            <Button
                              color="inherit"
                              variant="contained"
                              onClick={() => inspectionsStore.isEditing = true}
                              sx={{ color: '#444444', mr: '3px' }}
                            >
                                {t('edit')}
                            </Button>
                          )}
                          {inspectionSnap.inspectionToEdit && inspectionSnap.inspectionToEdit.id !== 0 && !inspectionSnap.isEditing && canDelete && (
                            <Button
                              color="inherit"
                              variant="contained"
                              onClick={handleDeleteClicked}
                              sx={{ color: '#444444' }}
                            >
                                {"Delete"}
                            </Button>
                          )}
                      </Toolbar>
                  </AppBar>

                  {inspectedEntitySnap.creating &&
                    <CreateInspectedEntity open={inspectedEntitySnap.creating} onActionClicked={() => inspectedEntityStore.creating = false} />
                  }
                  {inspectedEntitySnap.editing &&
                    <EditInspectedEntity open={inspectedEntitySnap.editing} onActionClicked={() => inspectedEntityStore.editing = false} />
                  }

                  <div id="modal-content" className="d-flex flex-column flex-column-fluid mx-5"
                       style={{ height: "calc(100vh - 100px)", marginTop: '80px' }}>
                      <InspectionSummary />
                      <div style={{ marginBottom: dateChangePermission ? "5px" : "20px" }}>
                          {inspectionSnap.isEditing ? (
                            <JointInspectedByCard />
                          ) : (
                            <InspectedByCard />
                          )}
                      </div>
                      {dateChangePermission &&
                        <div style={{ marginBottom: "20px" }}>
                            <InspectionDatePicker isEditing={inspectionSnap.isEditing} />
                        </div>
                      }
                      <div style={{paddingBottom: '50px'}}>
                          {inspectionSnap.detailsLoading ? (
                            <CustomLoadingDots />
                          ) : (
                              <InspectedEntitiesCardList />
                            )
                          }
                      </div>
                  </div>

                  <ConfirmationDialog
                    isOpen={isConfirmDialogOpen}
                    setIsOpen={setIsConfirmDialogOpen}
                    message="Confirm Deletion"
                    description="Are you sure you want to delete this inspection? This action cannot be undone."
                    onConfirmClicked={handleConfirmDelete}
                  />
                  {inspectionSnap.isEditing && inspectionSnap.inspectionToEdit && inspectionSnap.inspectionToEdit.id &&
                    <ModalFooterActions onClose={handleClose} onSave={handleSave} />
                  }
              </Dialog>
          </>

      </>
    );
}

export default EditAreaInspectionPage;


