import React, { useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  TextField,
  FormControlLabel,
  Switch,
  Divider,
  Button
} from '@mui/material';

const LogPanel = ({ scanFreq, setScanFreq, hasLog, setHasLog, sensorId, setSensorId }) => {
  const [isEditingSensorId, setIsEditingSensorId] = useState(false);

  const handleHasLogSwitch = (event) => {
    const result = event.target.checked;
    setHasLog(result);
  };

  const handleFrequencyChange = (event) => {
    setScanFreq(event.target.value);
  };

  const handleSensorIdChange = (event) => {
    setSensorId(event.target.value);
  };

  const toggleSensorIdEditing = () => {
    setIsEditingSensorId((prev) => !prev);
  };


  return (
    <Box
      className="d-flex flex-column mt-6"
      sx={{
        backgroundColor: hasLog ? '#ffffff' : '#F0F0F0',
        borderRadius: '5px',
        justifyContent: 'space-between',
        border: '1px solid #c7c7c7'
      }}
    >
      <Box className="d-flex flex-row" sx={{ justifyContent: 'space-between' }}>
        <Typography sx={{ fontWeight: 'bold', padding: '15px' }}>
          Service Logging?
        </Typography>
        <FormControlLabel
          control={<Switch checked={hasLog} onChange={handleHasLogSwitch} name="logSwitch" color="primary" />}
          label=""
        />
      </Box>
      <Divider />
      <Box className="p-4" sx={{ marginBottom: '105px' }}>
        <FormControl fullWidth margin="normal" disabled={!hasLog}>
          <div style={{ marginBottom: '20px' }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#3a3b3c' }}
            >
              Log Scan Frequency
            </Typography>
            <Box display="flex" alignItems="center" sx={{ paddingLeft: '10px', paddingTop: '10px' }}>
              <TextField
                value={scanFreq}
                onChange={handleFrequencyChange}
                type="number"
                inputProps={{ min: 0 }}
                style={{ width: '80px' }}
              />
              <Typography
                variant="body1"
                sx={{ fontWeight: 'bold', fontSize: '1.0rem', color: '#999', marginLeft: '10px' }}
              >
                Times per day
              </Typography>
            </Box>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 'bold', fontSize: '1.1rem', color: '#3a3b3c' }}
            >
              Sensor ID
            </Typography>
            {isEditingSensorId ? (
              // Render the TextField when editing
              <Box display="flex" alignItems="center">
                <TextField
                  value={sensorId}
                  onChange={handleSensorIdChange}
                  autoFocus
                />
                <Button onClick={toggleSensorIdEditing} sx={{ marginLeft: '10px' }} variant="contained" color="primary">
                  Save
                </Button>
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.0rem', color: '#999', padding: '10px' }}>
                  {sensorId.trim() === '' ? 'N/A' : sensorId}
                </Typography>
                <Button onClick={toggleSensorIdEditing} sx={{ marginLeft: '10px' }} variant="outlined" size="small">
                  Edit
                </Button>
              </Box>
            )}
          </div>
        </FormControl>
      </Box>
    </Box>
  );
};

export default LogPanel;
