import React, { useEffect } from "react";
import useAppStore from "../../../../../appStore";
import casesStore from "../../../caseStore";
import CustomLoadingDots from "../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import useServiceStore from "../../../../service-catalog/serviceStore";
import { Service } from "../../../../../shared/models/service.model";
import SearchableTreeView from "../../../../../shared/components/forms/widgets/searchable-tree-view/SearchableTreeView";

type CaseServiceFieldProps = {
  fieldName?: string;
};

const CaseServiceField: React.FC<CaseServiceFieldProps> = ({ fieldName = ''}) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);

  const {
    fetchServices,
    servicesLoading,
    services
  } = useServiceStore();

  useEffect(() => {
    fetchServices(user.organization?.id).then();
  }, [user]);


  const handleServiceSelected = (service: Service) => {
    casesStore.caseToEdit[fieldName] = service;
  };

  return (
    <div>
      {servicesLoading && <CustomLoadingDots />}

      <SearchableTreeView
        data={services}
        onItemSelected={handleServiceSelected}
        itemToEdit={null}
        showNone={false}
        text={''}
      />

    </div>
  );
};

export default CaseServiceField;