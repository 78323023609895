import React, { useEffect, useState } from "react";
import useAppStore from "../../../../../appStore";
import { Media } from "../../../../../shared/models/media.model";
import casesStore from "../../../caseStore";
import { useSnapshot } from "valtio";
import DynamicCaseFormSection from "../../dynamic-form/DynamicCaseFormSection";
import CustomLoadingDots from "../../../../../shared/components/page/popup-dialog/CustomLoadingDots";

const SageCaseCreate = ({ sections }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const snap = useSnapshot(casesStore);
  const { selectedLocation } = useAppStore();
  const [media, setMedia] = useState<Media[]>(casesStore.caseToEdit.media);

  useEffect(() => {
    if (snap.caseToEdit.id === null || snap.caseToEdit.id === 0) {
      casesStore.caseToEdit.created_by = user.employee;
      if (snap.caseToEdit.location.id == 0) {
          casesStore.caseToEdit.location = selectedLocation;
      }
      casesStore.caseToEdit.provider = user.organization;
    }
  }, [snap.caseToEdit.id]);


  useEffect(() => {
    casesStore.caseToEdit.media = media;
  }, [media]);

  if (snap.formLoading || sections.length === 0) {
    return <CustomLoadingDots />;
  }

  return (
    <DynamicCaseFormSection section={sections[casesStore.activeSection]} />
  );
};

export default SageCaseCreate;


