import { CommandGetCharts } from "./commands/cmdGetCharts";
import { CommandGetSurveySummary } from "./commands/cmdGetSurveySummary";
import { CommandGetSurveyQuestionAverages } from "./commands/cmdGetSurveyQuestionAverages";
import { CommandGetRespondents } from "./commands/cmdGetSurveyRespondants";
import { CommandGetReportingGroupScores } from "./commands/cmdGetReportingGroupScores";
import { Organization } from "../../models/organization.model";
import { CommandGetLocationSummaries } from "./commands/cmdGetLocationSummaries";
import { LocationModel } from "../../models/location.model";
import { CommandGetJointInspectionSummary } from "./commands/cmdGetJointInspectionSummary";
import { CommandGetSuggestionList } from "./commands/cmdGetSuggestionList";
import { CommandGetSuggestionItems } from "./commands/cmdGetSuggestionItems";
import { CommandGetCloudTags } from "./commands/cmdGetCloudTags";


export class AnalyticsService {

    async getCharts(provider_id, location_id, module_name, charts, startDate, endDate) {
        const cmd = new CommandGetCharts()
        return cmd.run(provider_id, location_id, module_name, charts, startDate, endDate)
    }

    async getSuggestions(provider_id, location_id) {
        const cmd = new CommandGetSuggestionList()
        return await cmd.run(provider_id, location_id)
    }

    async getCloudTags(provider_id, location_id, entity_type_string, score_lte) {
        const cmd = new CommandGetCloudTags();
        return await cmd.run(provider_id, location_id, entity_type_string, score_lte)
    }

    async getSuggestionItems(provider_id, location_id, type) {
        const cmd = new CommandGetSuggestionItems()
        return await cmd.run(provider_id, location_id, type)
    }

    async getSurveySummary(organization: Organization, surveyType: string, location?: LocationModel) {
        const cmd = new CommandGetSurveySummary()
        return cmd.run(organization, surveyType, location)
    }

    async getJointSummary(organization, location, fromDate=undefined, toDate=undefined) {
        const cmd = new CommandGetJointInspectionSummary()
        return cmd.run(organization, location, fromDate, toDate)
    }

    async getLocationsSummaries(organization: Organization, surveyType: string) {
        const cmd = new CommandGetLocationSummaries()
        return cmd.run(organization, surveyType)
    }

    async getRespondents(organization, location, reportingGroupEntryId, surveyType) {
        const cmd = new CommandGetRespondents()
        return cmd.run(organization, location, reportingGroupEntryId, surveyType)
    }

    async getQuestions(organization, location, reportingGroupEntryId, surveyType) {
        const cmd = new CommandGetSurveyQuestionAverages()
        return cmd.run(organization, location, reportingGroupEntryId, surveyType)
    }

    async getReportingGroupScore(organization, location, surveyType) {
        const cmd = new CommandGetReportingGroupScores()
        return cmd.run(organization, location, surveyType)
    }








}