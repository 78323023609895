import React, { useEffect, useState } from "react";
import { Button, Divider } from "@mui/material";
import { ConfirmationDialog } from "../../../../shared/components/page/popup-dialog/ConfirmationDialog";
import EditCaseType from "./components/right-panel/EditCaseType";
import { CaseType } from "../../../../shared/models/caseType.model";
import CaseTypeTable from "./components/left-panel/CaseTypeTable";
import { useSnapshot } from "valtio";
import caseTypeStore from "./caseTypeStore";

const CaseTypesDualViewer = () => {

  const snap = useSnapshot(caseTypeStore);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);


  const handleAddCaseType = () => {
    caseTypeStore.selectedCaseType = new CaseType();
  };

  const handleSave = async (spaceType?) => {

  };

  const handleConfirmDelete = async () => {

  };

  const handleDelete = () => {
    setConfirmDeleteOpen(true);
  };

  return (
    <div className="card">
      <div className="card-body d-flex flex-row" style={{ alignItems: 'flex-start' }}>

        {/* Left */}
        <div className="d-flex flex-column flex-row-auto w-250px" style={{ minHeight: '600px', overflow: 'auto' }}>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h3>Types</h3>
            <Button variant="contained" color="primary" onClick={handleAddCaseType}>
              + Case Type
            </Button>
          </div>
          <CaseTypeTable />
        </div>

        <Divider orientation="vertical" flexItem style={{ backgroundColor: 'black', marginLeft: '15px' }} />

        {/* Right */}
        <div className="d-flex flex-column flex-row-fluid ms-5">
          {snap.selectedCaseType !== null ? (
            <>
              <EditCaseType />
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center"
                 style={{ flex: 1, textAlign: "center", padding: "20px", marginTop: "50px" }}>
              <div>
                <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "15px" }}>
                  No Case Type Selected.
                </p>
                <p>Select a type from the left panel or add a new one.</p>
                <Button color="primary" onClick={handleAddCaseType} style={{ height: '35px' }}>
                  + Case Type
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      {confirmDeleteOpen && (
        <ConfirmationDialog
          isOpen={confirmDeleteOpen}
          setIsOpen={setConfirmDeleteOpen}
          message={'Unlink Form?'}
          description={"Are you sure you want to unlink this form?"}
          onConfirmClicked={handleConfirmDelete}
        />
      )}
    </div>
  );
};

export default CaseTypesDualViewer;