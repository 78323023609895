
export class CloudTag {
  id: number;
  tag_type: string;
  name: string;
  weight: number;
  count: number;

  constructor() {
    this.id = 0;
    this.tag_type = '';
    this.name = '';
    this.weight = 0.0;
    this.count = 0;
  }
}