import Dialog from "@mui/material/Dialog";
import ModalTransition from "../../../../../shared/components/page/transitions/ModalTransition";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { useCallback, useEffect, useState } from "react";
import { ServiceLogService } from "../../../../../shared/services/service-logs/serviceLogService";
import { ServiceLogEntry } from "../../../../../shared/models/serviceLogEntry.model";
import { formatSlashedDate, getPacificTime } from "../../../../../shared/utils/table-modifier-util/tableDataUtil";
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers";

const ServiceLogViewer = ({ open, onActionClicked, id }) => {

  const [entries, setEntries] = useState<ServiceLogEntry[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchLog = useCallback(async () => {
    setIsLoading(true);
    try {
      const service = new ServiceLogService();
      const logData = await service.getLog(id);
      const sortedData = logData.sort((a: ServiceLogEntry, b: ServiceLogEntry) => {
        return new Date(b.service_date).getTime() - new Date(a.service_date).getTime();
      });

      setEntries(sortedData);
    } catch (err) {
      console.log(err instanceof Error ? err.message : String(err));
    } finally {
      setIsLoading(false);
    }
  }, [id]);


  useEffect(() => {
    { id !== 0 &&
      fetchLog().then();
    }
  }, [fetchLog]);


  return (
    <Dialog fullScreen open={open} onClose={onActionClicked} TransitionComponent={ModalTransition} PaperProps={{ style: { overflowX: 'hidden' } }}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onActionClicked} aria-label="close">
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{padding: '10px', paddingTop: '5px'}}>
        <Box sx={{ marginBottom: "15px", padding: '20px' }}>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-row-dashed align-middle gs-0 gy-3">
                {/* End Table Head */}
                {/* Begin Table Body */}
                <tbody>
                {entries.map((entry) => (
                  <tr key={entry?.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="me-3">
                          <img src={toAbsoluteUrl("/white-labeling/verde/media/menu-icons/inspectorProfile.png")}
                               style={{ width: "40px", height: "40px", marginRight: "2px" }} />
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                          <a className="text-gray-800 mb-1" style={{fontSize: '1.2rem'}}>
                            {entry?.serviced_by?.person.first_name} {entry?.serviced_by?.person.last_name}
                          </a>
                        </div>
                      </div>
                    </td>

                    <td className="text-start">
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                          {formatSlashedDate(entry?.service_date)}
                        </div>
                        <div>
                          {getPacificTime(entry?.service_date)}
                        </div>
                      </div>

                    </td>

                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </Box>
      </Box>
    </Dialog>

  );
};

export default ServiceLogViewer;