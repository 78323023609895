import React from "react";

const CaseTypeRow = (props) => {
  const {
    type,
    onSelect,
    isSelected
  } = props;

  console.log(type);

  return (
    <>
      <tr
        onClick={(e) => {
          e.preventDefault();
          onSelect(type);
        }}
        className={isSelected ? "bg-light-success" : ""}
        style={{cursor: "pointer"}}
      >
        <td>
          <div className="d-flex align-items-center">
            <div className="d-flex justify-content-start flex-column">
              <a className="text-gray-800 mb-1 ms-2 fs-6">
                {type.name}
              </a>
            </div>
          </div>
        </td>
      </tr>
    </>
  )
}

export default CaseTypeRow;