import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Box,
  Typography,
  CircularProgress,
  Button,
  IconButton, Chip
} from "@mui/material";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import useAppStore from "../../../../appStore";
import { ServiceLogService } from "../../../../shared/services/service-logs/serviceLogService";
import { ServiceLogArea } from "../../../../shared/models/serviceLogArea";
import ServiceLogViewer from "../../../location-summary/components/service-log/components/ServiceLogViewer";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useSnapshot } from "valtio";
import { serviceLogStore } from "../../../location-summary/components/service-log/serviceLogStore";
import WhereAndWhatFilter from "../../../location-summary/components/service-log/components/WhereAndWhatFilter";
import CancelIcon from "@mui/icons-material/Cancel";
import ComponentViewer from "../../../../shared/components/page/modal/ComponentViewer";
import QrCodeQuickSelect from "./QrCodeQuickSelect";


const ServiceLogWidget = () => {
  const { appImages, selectedLocation } = useAppStore();
  const snap = useSnapshot(serviceLogStore);
  const service = new ServiceLogService();
  const [filterOpen, setFilterOpen] = useState(false);
  const [log, setLog] = useState<ServiceLogArea[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [logsOpen, setLogsOpen] = useState(false);
  const [areaIdToView, setAreaIdToView] = useState(0);
  const [scannerOpen, setScannerOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const locations = await service.getLogList(selectedLocation.id, snap.filteredFloor?.id || 0, snap.filteredType?.id || 0, undefined);
        setLog(locations);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [snap.filteredFloor, snap.filteredType, selectedLocation.id]);


  const handleCloseLogs = () => {
    setLogsOpen(false);
  };

  const handleOpenFilter = () => {
    setFilterOpen(true);
  }

  const handleCloseFilter = () => {
    setFilterOpen(false);
  }

  const handleCloseScanner = () => {
    setScannerOpen(false);
  }

  const removeSpaceFilter = () => {
    serviceLogStore.filteredBuilding = null;
    serviceLogStore.filteredFloor = null;
    serviceLogStore.filteredType = null;
  }

  return (
    <Card
      sx={{
        marginTop: '15px',
        marginBottom: '15px',
        boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
        position: 'relative',
        border: '1px solid #c7c7c7',
      }}
    >
      {/* Top Row */}
      <Box
        sx={{
          backgroundColor: '#f1f1f1',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 16px',
          borderRadius: '4px 4px 0 0'
        }}
      >
        {/* Left Side: Location Name */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {snap.filteredFloor && snap.filteredBuilding && snap.filteredType &&
            <Chip
              key={snap.filteredFloor?.id}
              label={snap.filteredFloor.name + " / " + snap.filteredType.name}
              onDelete={removeSpaceFilter}
              deleteIcon={<CancelIcon />}
              sx={{ m: 0.5, fontSize: "1rem", backgroundColor: "#e2e2e2" }}
            />
          }
        </div>

        {/* Right Side: Icons */}
        <Box display="flex" alignItems="center">
          <IconButton onClick={handleOpenFilter} sx={{ color: "#555" }}>
            <FilterAltIcon sx={{ fontSize: "1.6rem" }} />
          </IconButton>
          <IconButton onClick={() => setScannerOpen(true)} sx={{ color: "#555" }}>
            <QrCode2Icon sx={{ fontSize: "1.6rem" }} />
          </IconButton>
        </Box>
      </Box>

      {/* Log View */}
      <CardContent sx={{ flex: "1 1 auto" }}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : log.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100%">
            <img src={appImages.iconNoData} alt="No log entries" style={{ width: "60px", marginBottom: "10px" }} />
            <Typography variant="caption" sx={{ color: "#777" }}>
              No log entries for selected filters!
            </Typography>
          </Box>
        ) : (
          <Box sx={{ overflow: 'auto' }}>
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse'
              }}
            >
              <tbody>
              {log.map((area, idx) => (
                <tr
                  key={area.id}
                  style={{
                    borderBottom: '1px solid #ddd',
                    ...(idx === 0 && { borderTop: '1px solid #ddd' })
                  }}
                >
                  <td style={{ padding: "8px" }}>
                    <div className={"d-flex flex-row align-items-center"}>
                      <img src={appImages.iconLog} style={{ width: "25px", height: "25px", marginRight: "6px" }} />
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {area?.entity?.name || ""}
                      </Typography>
                    </div>
                  </td>
                  <td style={{ display: "flex", justifyContent: "end", textAlign: "center", padding: "8px" }}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        setAreaIdToView(area.id || 0);
                        setLogsOpen(true);
                      }}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </Box>
        )}
      </CardContent>

      {/* Modals */}
      {logsOpen && (
        <ServiceLogViewer
          open={logsOpen}
          onActionClicked={handleCloseLogs}
          id={areaIdToView}
        />
      )}
      {filterOpen && (
        <WhereAndWhatFilter open={filterOpen} onClose={handleCloseFilter} />
      )}
      {scannerOpen && (
        <ComponentViewer
          selectedPage={<QrCodeQuickSelect onClose={handleCloseScanner} />}
          onActionClicked={() => setScannerOpen(false)}
          title={'QR Code Reader'}
          color={'#c2c2c2'}
        />
      )}

    </Card>
  );
};

export default ServiceLogWidget;
