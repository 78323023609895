
import httpClient from "../../../utils/http-clients/djangoHttpClient";
import useTracer from "../../../framework/tracer/tracer";
import { WorkItemSerializer } from "../../../models/serializers/workItemSerializer";


export class CommandGetWorkItemPrefill {

  SERVICE = process.env.REACT_APP_API_FACILITIES_SERVICE_URL;

  getUrl() {

    return  `${this.SERVICE}/workitems/prefill/`;
  }

  async run(entity_id: number, module: string) {

    const url = this.getUrl()

    const params = {
      params: {
        type: module,
        id: entity_id,
      }
    }

    const tracer = useTracer.getState()
    tracer.trace('WorkItems', 'Api', `GET ${url}`)

    try {
      const response = await httpClient.get(url, params);
      return this.deserialize(response.data);
    } catch (error) {
      tracer.trace('WorkItems', 'Error', `${error}`)
      console.error('Error fetching data:', error);
      throw error;
    }finally {
      tracer.trace('WorkItems', 'Api', 'Success')
    }

  }

  deserialize(data) {

    const serializer = new WorkItemSerializer()
    const workItem = serializer.deserialize(data)

    return workItem
  }

}