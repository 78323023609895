import React from 'react';
import cytoscape from 'cytoscape';
import CytoscapeComponent from 'react-cytoscapejs'; // Note: default import
import fcose from 'cytoscape-fcose';
import useAppStore from "../../../../appStore";
import Card from "@mui/material/Card";

cytoscape.use(fcose);

const GraphTest = () => {

  const {whiteLabelProps} = useAppStore();

  // Example elements matching the second picture’s nodes and edges
  const elements = [
    { data: { id: 'men', label: "Men's Restrooms" } },
    { data: { id: 'dispensers', label: "Dispensers" } },
    { data: { id: 'building1', label: "Building 1" } },
    { data: { id: 'trash', label: "Trash" } },
    { data: { id: 'floor2', label: "Floor 2" } },
    { data: { id: 'floor3', label: "Floor 3" } },
    // Edges
    { data: { source: 'men', target: 'dispensers', label: '' } },
    { data: { source: 'men', target: 'building1', label: '' } },
    { data: { source: 'men', target: 'trash',      label: '' } },
    { data: { source: 'men', target: 'floor2',     label: '' } },
    { data: { source: 'men', target: 'floor3',     label: '' } },
    { data: { source: 'dispensers', target: 'floor2',  label: '' } },
    { data: { source: 'dispensers', target: 'floor3',  label: '' } },
    { data: { source: 'building1',  target: 'floor2',  label: '' } },
    { data: { source: 'building1',  target: 'floor3',  label: '' } },
    // Add more edges as needed
  ];

  // Example stylesheet for bigger labels, dark arrows, etc.
  const stylesheet = [
    {
      selector: 'node',
      style: {
        label: 'data(label)',
        'font-size': '15px',
        color: '#fff',
        'text-halign': 'center',
        'text-valign': 'center',
        'background-color': whiteLabelProps.primary_color,
        'text-outline-color': '#333',
        'text-outline-width': 1,
        'border-width': 2,
        'border-color': '#333',
        // For a little padding around text:
        width: 'label',
        height: 'label',
        padding: '10px'
      },
    },
    {
      selector: 'edge',
      style: {
        'curve-style': 'bezier',
        'width': 3,
        'line-color': '#666',
        'target-arrow-shape': 'triangle',
        'target-arrow-color': '#666',
        'arrow-scale': 1.5
      },
    },
  ];

  // A simple left-to-right “tree” layout
  const layout = {
    name: 'breadthfirst',
    fit: true,
    directed: true,
    padding: 20,
    spacingFactor: 1.2,
  };

  return (
    <Card
      sx={{
        marginTop: '15px',
        marginBottom: '15px',
        boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
        border: '1px solid #c7c7c7',
        position: 'relative'
      }}
    >
    <CytoscapeComponent
      elements={elements}
      style={{ width: '600px', height: '400px', border: '1px solid #ccc' }}
      stylesheet={stylesheet}
      layout={layout}
    />
    </Card>
  );
};

export default GraphTest;
