import { CommandDeleteWorkItem } from "./commands/cmdDeleteWorkItem";
import { CommandUpdateWorkItem } from "./commands/cmdUpdateWorkItem";
import { CommandGetWorkItemPrefill } from "./commands/cmdGetWorkItemPrefill";
import { CommandGetWorkItemsByEmployee } from "./commands/cmdGetWorkItemsByEmployee";
import { CommandGetWorkItems } from "./commands/cmdGetWorkItems";
import { CommandCreateWorkItem } from "./commands/cmdCreateWorkItem";
import { CommandGetWorkItemDetails } from "./commands/cmdGetWorkItemDetails";
import { CommandCreateWorkItemPrefill } from "./commands/cmdCreateWorkItemPrefill";

export class WorkItemsService {

  async getWorkItems(provider_id: number | undefined, location_id: number | null, status?: string | undefined, limit = Number.MAX_SAFE_INTEGER, offset=0, order_by: string = 'most_recent') {
    const cmd = new CommandGetWorkItems();
    return await cmd.run(provider_id, location_id, status, limit, offset, order_by);
  }

  async getWorkItemDetails(workItemId) {
    const cmd = new CommandGetWorkItemDetails();
    return await cmd.run(workItemId)
  }

  async getWorkItemsForEmployee(provider_id: number | undefined, employee_id: number | undefined, status) {
    const cmd = new CommandGetWorkItemsByEmployee();
    return await cmd.run(provider_id, employee_id, status);
  }

  async createWorkItem(workItem) {
    const cmd = new CommandCreateWorkItem()
    return await cmd.run(workItem)
  }

  async createWorkItemPrefill(source) {
    const cmd = new CommandCreateWorkItemPrefill()
    return await cmd.run(source)
  }

  async updateWorkItem(workItem) {
    const cmd = new CommandUpdateWorkItem();
    return await cmd.run(workItem);
  }

  async deleteWorkItem(workItemId) {
    const cmd = new CommandDeleteWorkItem();
    return await cmd.run(workItemId);
  }

  async getPrefill(entity_id: number, module: string) {
    const cmd = new CommandGetWorkItemPrefill();
    return cmd.run(entity_id, module)
  }

  // async getWorkItemStatusCounts(provider_id, location_id) {
  //   const cmd = new CommandGetStatusCounts();
  //   return await cmd.run(provider_id, location_id);
  // }

}
