import { Field, Section } from "../../../../../shared/models/caseForm.model";

export function departmentSection(): Section {
  const departmentSection = new Section();

  departmentSection.name = 'Department Selection';
  departmentSection.sage_prompt = 'Hi there! Which department is this related to?';
  departmentSection.show_after_creation = false;

  const field = new Field();
  field.name = 'Department';
  field.type = 'Department';
  field.is_enabled = true;
  field.is_required = true;
  field.data_source = 'department';

  departmentSection.fields.push(field);
  return departmentSection;
}

export function caseTypeSection(): Section {
  const caseTypeSection = new Section();

  caseTypeSection.name = 'Case Type Selection';
  caseTypeSection.sage_prompt = 'Please pick one of these options';
  caseTypeSection.show_after_creation = false;

  const field = new Field();
  field.name = 'Case Type';
  field.type = 'CaseType';
  field.is_enabled = true;
  field.is_required = true;
  field.data_source = 'case_type';

  caseTypeSection.fields.push(field);

  return caseTypeSection;
}

export function confirmationSection(): Section {
  const confirmationSection = new Section();

  confirmationSection.name = 'Summary';
  confirmationSection.sage_prompt = 'Nice! Here\'s what I have, everything look okay?';
  confirmationSection.show_after_creation = false;

  const field = new Field();
  field.name = 'Summary';
  field.type = 'Summary';
  field.is_enabled = true;
  field.is_required = false;
  field.data_source = '';

  confirmationSection.fields = [field];

  return confirmationSection;
}

export function submissionStatus(): Section {
  const submissionStatusSection = new Section();

  submissionStatusSection.name = 'Status';
  submissionStatusSection.sage_prompt = 'Ok your case was submitted!';
  submissionStatusSection.show_after_creation = true;

  const field = new Field();
  field.name = 'Submitted';
  field.type = 'Submitted';
  field.is_enabled = true;
  field.is_required = false;
  field.data_source = '';

  submissionStatusSection.fields = [field];

  return submissionStatusSection;
}