import React, { useEffect, useState } from "react";
import useAppStore from "../../../../../appStore";
import { ContactService } from "../../../../../shared/services/location-contacts/contactService";
import { LocationContact } from "../../../../../shared/models/locationContact.model";
import casesStore from "../../../caseStore";
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  List,
  ListItem,
  TextField,
  Typography
} from "@mui/material";
import { useSnapshot } from "valtio";
import CancelIcon from "@mui/icons-material/Cancel";
import { TextFieldButton } from "../../../../../shared/components/buttons/TextFieldButton";
import { KeyboardReturn } from "@mui/icons-material";
import EmployeeDetailRow from "../../../../../shared/components/page/tables/EmployeeDetailRow";

type CaseContactFieldProps = {
  fieldName?: string;
};

const CaseContactField: React.FC<CaseContactFieldProps> = () => {
  const {selectedLocation} = useAppStore();
  const snap = useSnapshot(casesStore);
  const initialSelectedContacts = (casesStore.caseToEdit.location_contact ?? null) as LocationContact | null;
  const [selected, setSelected] = useState<LocationContact | null>(initialSelectedContacts);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [contactSearchResult, setContactSearchResult] = useState<LocationContact[]>([]);
  const [searchFor, setSearchFor] = useState('');

  useEffect(() => {
    const fetchContacts = async () => {
      setIsLoading(true);
      try {
        const contactService = new ContactService();
        const contactsData = await contactService.getContacts(snap.caseToEdit.location.id !== 0 ? snap.caseToEdit.location.id : selectedLocation.id, searchFor);
        setContactSearchResult(contactsData);
      } catch (error: any) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (searchFor !== '') {
      void fetchContacts();
    }
  }, [searchFor, snap.caseToEdit.location.id, selectedLocation.id]);

  const handleSearch = () => {
    setSearchFor(searchQuery);
  };


  const handleRemoveContact = () => {
    setSelected(null);
    casesStore.caseToEdit.location_contact = null;
  };

  const handleListItemClick = (contact: LocationContact) => {
    setSelected(contact);
    casesStore.caseToEdit.location_contact = contact;
    setSearchFor('');
    setSearchQuery('');
    setContactSearchResult([]);
  };

  const renderSelected = () => {
    if (selected)  {
      return  (
      <Chip
        key={selected.id}
        label={`${selected.employee.person.first_name} ${selected.employee.person.last_name}`}
        onDelete={() => handleRemoveContact()}
        deleteIcon={<CancelIcon />}
        sx={{ m: 0.5, fontSize: '1rem', backgroundColor: '#e7e7e7' }}
      />
    );
   } else {
      return <></>
    }
  };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {selected && (
            <Box sx={{ flexGrow: 1, paddingTop: '10px', paddingBottom: '10px' }}>
              {renderSelected()}
            </Box>
          )}
        </Box>
      </Box>

      <div style={{ display: 'flex', flexDirection: 'column'}}>
        <div className="d-flex flex-row">
          <TextField
            variant="outlined"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSearch();
            }}
            sx={{
              width: '90%',
              '& .MuiOutlinedInput-root': {
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                height: '45px',
                '& .MuiInputBase-input': {
                  height: '100%',
                  padding: '16.5px 14px',
                },
              },
            }}
          />
          <TextFieldButton onClick={handleSearch}>
            <KeyboardReturn />
          </TextFieldButton>
        </div>

        <div style={{ flex: 1, overflow: 'auto' }}>
          {contactSearchResult.length > 0 && (
            <List>
              {isLoading ? (
                <CircularProgress />
              ) : (
                contactSearchResult.map((contact) => {
                  const isSelected = !!(selected && (selected.id === contact.id));
                  return (
                    <ListItem
                      key={contact.id}
                      button
                      selected={isSelected}
                      onClick={() => handleListItemClick(contact)}
                    >
                      <Checkbox
                        edge="start"
                        checked={isSelected}
                        tabIndex={-1}
                        disableRipple
                      />
                      <EmployeeDetailRow employee={contact.employee} />
                    </ListItem>
                  );
                })
              )}
            </List>
          )}
        </div>
      </div>

      {error && <Typography color="error">{error}</Typography>}
    </>
  );
};

export default CaseContactField;
