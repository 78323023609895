import React, { useEffect, useState } from "react";
import { useSnapshot } from "valtio";
import { Button, Card, Divider, Typography } from "@mui/material";
import { suggestionsStore } from "./suggestionsStore";
import CustomLoadingDots from "../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import CustomError from "../../../../shared/components/page/popup-dialog/CustomError";
import SuggestionRow from "./components/SuggestionRow";
import SuggestionProgress from "./components/SuggestionProgress";
import SuggestionActionMenu from "./components/SuggestionActionMenu";
import { AnalyticsService } from "../../../../shared/services/analytics/analyticsService";
import useAppStore from "../../../../appStore";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";


const Suggestions = () => {
  const snap = useSnapshot(suggestionsStore);
  const {t} = useTranslation();
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const {selectedLocation} = useAppStore();
  const [showAll, setShowAll] = useState(false);
  const service = new AnalyticsService();


  useEffect(() => {
    const fetchData = async () => {
      try {
        suggestionsStore.isLoading = true;
        suggestionsStore.suggestions = await service.getSuggestions(user.organization.id, selectedLocation.id);
      } catch (error) {
        console.error(error);
      } finally {
        suggestionsStore.isLoading = false;
        console.log(suggestionsStore.suggestions);
      }
    };

    suggestionsStore.suggestions = [];
    if (selectedLocation?.id !== 0) {
      fetchData().then();
    }

  }, [user, selectedLocation.id]);


  if (snap.isLoading) {
    return <CustomLoadingDots />;
  }
  if (snap.error) {
    return <CustomError error={snap.error} />;
  }

  return (
    <Card
      variant="outlined"
      sx={{
        marginBottom: '5px',
        padding: '15px',
        boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
        width: '100%'
      }}
    >

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2
        }}
      >
        <img src={"/white-labeling/verde/media/menu-icons/lightbulb.png"} alt="custom-icon"
             style={{ width: '40px', height: "40px", marginRight: '15px' }} />
        <Typography variant="h6" component="div" sx={{fontWeight: 'bold'}}>
          {snap.suggestions.length} {t('suggestion')}{snap.suggestions.length > 1 && 's'}
        </Typography>
      </Box>

      <Divider textAlign="center" className="mb-5" sx={{bgcolor: 'black'}}></Divider>


      {suggestionsStore.suggestions.map((suggestion, index) => (
        <div style={{ marginBottom: "10px" }} key={index}>
          <SuggestionRow suggestion={suggestion} />
        </div>
      ))}

      {snap.suggestions.length > 3 && (
        <Button
          variant="text"
          onClick={() => setShowAll((prev) => !prev)}
        >
          {showAll ? 'Show Less' : 'Show More...'}
        </Button>
      )}

      {snap.open && <SuggestionActionMenu />}
    </Card>
  );
};

export default Suggestions;
