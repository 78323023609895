import React from "react";
import GreetingDashboardCard from "../components/greeting/GreetingDashboardCard";
import { Divider, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import useAppStore from "../../../appStore";
import DashboardSection from "../components/DashboardSection";
import ThingsIveToldSage from "../components/cases/ThingsIveToldSage";
import CustomLoadingDots from "../../../shared/components/page/popup-dialog/CustomLoadingDots";
import ClientSummary from "./monthly-summary/MonthClientSummary";
import { useTranslation } from "react-i18next";
import ServiceLogWidget from "./service-log-widget/ServiceLogWidget";
import JointInspectionTile from "./monthly-summary/joint-inspection-summary/JointInspectionTile";

const ClientDashboard = () => {

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));
  const {selectedLocation} = useAppStore();
  const { t } = useTranslation();

  if (selectedLocation.id === 0) {
    return (<CustomLoadingDots />);
  }

  return (
    <div className={'mb-10'}>
      <GreetingDashboardCard />

      <div style={{display: 'flex', flexDirection: 'column', marginTop: isMobile ? '0' : '20px'}}>

        <div style={{ width: '100%' }}>
          <div>
            <Grid container>
              <Grid item xs={12} md={12}>
                <DashboardSection title={t('joint-inspections')} tile={<JointInspectionTile />} />
              </Grid>
              <Grid item xs={12} md={12}>
                <DashboardSection title={t('service-logs')} tile={<ServiceLogWidget />} />
              </Grid>
              <Grid item xs={12} md={12}>
                <DashboardSection title={t('things-ive-told-sage')} tile={<ThingsIveToldSage />} />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ClientDashboard;