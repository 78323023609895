import { Divider, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import FormList from "./components/forms/FormList";
import EditForm from "./components/forms/edit-form/EditForm";
import caseTypeStore from "./components/case-types/caseTypeStore";
import { useSnapshot } from "valtio";
import CaseTypeDualViewer from "./components/case-types/CaseTypeDualViewer";

const FormBuilderPage = () => {
  const [tab, setTab] = useState(1);
  const snap = useSnapshot(caseTypeStore);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleSaveForm = () => {

  }

  const handleCloseBuilder = () => {
    caseTypeStore.selectedFormSection = null;
    caseTypeStore.formToEdit = null;
    caseTypeStore.formBuildOpen = false;
  }

  return (
    <>
    <Tabs value={tab} onChange={handleTabChange}>
      <Tab label={'Case Types'} value={1} />
      <Tab label={'Forms'} value={2} />
    </Tabs>
    <Divider sx={{ bgcolor: "#999", marginBottom: "15px" }} />

    {tab === 1 &&
      <CaseTypeDualViewer />
    }
    {tab === 2 &&
      <FormList />
    }
    {snap.formBuildOpen && (
      <EditForm
        open={snap.formBuildOpen}
        onClose={handleCloseBuilder}
        onSave={handleSaveForm}
      />
    )}
    </>
  );
}

export default FormBuilderPage;