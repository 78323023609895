import React from "react";
import Dialog from "@mui/material/Dialog";
import ModalTransition from "../../../../shared/components/page/transitions/ModalTransition";
import { AppBar, DialogContent, IconButton, Toolbar, Typography, Box, DialogActions, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const QrScanResult = ({ open, onClose, image, message }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={ModalTransition}
    >

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          px: 2,
        }}
      >
        {/* Image section */}
        {image && (
          <Box sx={{ textAlign: "center", mb: 3 }}>
            <img src={image} alt="QR Scan" style={{ maxWidth: "100%", height: "auto" }} />
          </Box>
        )}

        {/* Message container */}
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            borderRadius: 2,
            p: 3,
            width: "100%",
            maxWidth: 600,
            textAlign: "center",
            mx: "auto",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              color: "#333",
            }}
          >
            {message}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          variant="contained"
          onClick={onClose}
          sx={{color: '#333', backgroundColor: '#f5f5f5', width: '100%', height: '60px', fontSize: '1.2rem'}}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QrScanResult;

