import { BaseSerializer } from "./baseSerializer";
import { CloudTag } from "../cloudTag.model";

export class CloudTagSerializer extends BaseSerializer {


  public deserialize( data ): any {

    let cloud_tag = new CloudTag();

    this._copyAttributes(cloud_tag, data);

    return cloud_tag;

  }

}