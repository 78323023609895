import Box from "@mui/material/Box";
import { FormControl, Grid, Select } from "@mui/material";
import React, { useEffect } from "react";
import { GroupEntry } from "../../../../../shared/models/groupEntry.model";
import useAppStore from "../../../../../appStore";
import casesStore from "../../../caseStore";
import CustomLoadingDots from "../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { useSnapshot } from "valtio";
import SelectableTile
  from "../../../../inspections/components/edit-inspection/components/edit-inspected-entity/components/space-selection/components/selectable-tiles/SelectableTile";
import MenuItem from "@mui/material/MenuItem/MenuItem";


const DepartmentSelection = ({manual = false}) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const snap = useSnapshot(casesStore);

  useEffect(() => {
    if (user.organization.id) {
      casesStore.handleFetchDepts(user.organization.id)
    }
  }, [user?.organization?.id]);

  const handleDepartmentChange = (dept: GroupEntry) => {
    casesStore.caseToEdit.department = dept;
    casesStore.handleFetchTypes(user.organization?.id, dept.id).then(() => casesStore.activeSection++);
  };

  return (
    <FormControl fullWidth style={{ marginBottom: 15 }}>
      <Box>
        {snap.departmentsLoading ? (
          <CustomLoadingDots />
        ) : (
          <div>
            {!manual ? (
              <Grid container>
                {casesStore.availableDepartments.map((data, index) => (
                  <SelectableTile
                    key={index}
                    disabled={false}
                    data={data}
                    selectedIndex={-1}
                    onTileClicked={handleDepartmentChange}
                    icon={'abstract-13'}
                    sageModal={true}
                  />
                ))}
              </Grid>
            ) : (
              <>
                <span className="text-gray-700">Department</span>
                <Select
                  sx={{width: "100%"}}
                  value={snap.caseToEdit.department?.name || ""}
                >
                  {casesStore.availableDepartments.map((dept) => (
                    <MenuItem key={dept.id} value={dept.name} onClick={() => handleDepartmentChange(dept)}>{dept.name}</MenuItem>
                  ))}
                </Select>
              </>
            )}
          </div>
        )}
      </Box>
    </FormControl>
  );
};

export default DepartmentSelection;