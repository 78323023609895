import Card from "@mui/material/Card";
import React, { useState } from "react";
import QrCodeReader from "../../../qr-scanner/QrCodeReader";
import Box from "@mui/material/Box";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ComponentViewer from "../../../../shared/components/page/modal/ComponentViewer";
import { useTranslation } from "react-i18next";
import SensorCodeManualEntry from "./SensorCodeManualEntry";

const ServiceScanTile = () => {
  const { t } = useTranslation();
  const [scannerOpen, setScannerOpen] = useState(false);
  const [manualEntryOpen, setManualEntryOpen] = useState<boolean>(false);

  const handleClose = (response: any) => {
    setScannerOpen(false);
  }

  const handleCloseSelector = () => {
    setManualEntryOpen(false);
  }

  return (
    <Card
      sx={{
        marginTop: '15px',
        marginBottom: '15px',
        boxShadow: '0 1px 4px rgba(0,0,0,0.1)',
        position: 'relative'
      }}
    >
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px 16px',
          borderRadius: '4px 4px 0 0'
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold', color: '#555' }}>
          {t('see-qr-code')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px',
        }}
      >

        <Box sx={{display: 'flex', flexDirection: 'column', marginTop: '5px', width: '50%'}}>
          <Button
            variant={"contained"}
            onClick={() => setScannerOpen(true)}
            sx={{
              fontWeight: "bold",
              fontSize: '1.1rem',
              borderRadius: 1,
              marginTop: '10px',
              padding: '10px',
              zIndex: 900,
              width: '100%',
              backgroundColor: '#f5f5f5',
              color: '#555',
              height: '55px'
            }}
          >
            {t('scan-now')}
          </Button>
          <Button
            variant={"contained"}
            onClick={() => setManualEntryOpen(true)}
            sx={{
              fontWeight: "bold",
              fontSize: '1.1rem',
              borderRadius: 1,
              marginTop: '15px',
              padding: '10px',
              zIndex: 900,
              width: '100%',
              backgroundColor: '#f5f5f5',
              color: '#555',
              height: '55px'
            }}
          >
            {t('enter-code')}
          </Button>
        </Box>
        <Box
          component="img"
          src={toAbsoluteUrl('/white-labeling/verde/media/menu-icons/QR.png')}
          alt="QR Scanner Illustration"
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "160px",
            height: "auto",
            transform: "translate(5%, 0%)",
          }}
        />
      </Box>

      {scannerOpen && (
        <ComponentViewer
          selectedPage={<QrCodeReader onClose={handleClose} />}
          onActionClicked={() => setScannerOpen(false)}
          title={'QR Code Reader'}
          color={'#c2c2c2'}
        />
      )}

      {manualEntryOpen && (
        <SensorCodeManualEntry open={manualEntryOpen} onClose={handleCloseSelector} />
      )}

    </Card>
  );
};

export default ServiceScanTile;
