import React from 'react';
import PageHeader from "../../../../shared/components/page/headers/PageHeader";
import GraphTest from "./GraphTest";
import TagCloud from "./TagCloud";
import Grid from "@mui/material/Grid";
import DashboardSection from "../../../dashboard/components/DashboardSection";
import Suggestions from "../../../dashboard/components/suggestions/Suggestions";
import SubHeader from "../../../../shared/components/page/headers/SubHeader";
import { Divider, useMediaQuery, useTheme } from "@mui/material";
import SiteHealthCard from "../site-health/components/tabs/corp-overview/components/card/SiteHealthCard";

const RootCauseAnalysis = () => {

  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));

  return (
    <div style={{ width: '600px' }}>
      <PageHeader title={'Root Cause Analysis'} showAction={false}/>
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: isMobile ? '0' : '20px' }}>

        <div style={{ width: '100%' }}>
          <div>
            <Grid container>
              <Grid item xs={12} md={12}>
                <TagCloud />
              </Grid>
              <Grid item xs={12} md={12}>
                <GraphTest />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RootCauseAnalysis;