import { dummyForms } from "./table/dummyForms";
import FormRow from "./table/FormRow";
import React from "react";
import PageHeader from "../../../../shared/components/page/headers/PageHeader";
import caseTypeStore from "../case-types/caseTypeStore";
import { CaseForm, DynamicCaseForm } from "../../../../shared/models/caseForm.model";

const FormList = () => {

  const handleNewForm = () => {
    caseTypeStore.formToEdit = new CaseForm();
    caseTypeStore.selectedFormSection = null;
    caseTypeStore.formBuildOpen = true;
  }

  const handleEditForm = (form) => {
    caseTypeStore.formToEdit = form;
    caseTypeStore.selectedFormSection = null;
    caseTypeStore.formBuildOpen = true;
  }

  return (
    <>
      <div className="card">
        <div style={{padding: '15px', paddingLeft: '30px', paddingTop: '30px', paddingRight: '20px'}}>
          <PageHeader title={'Forms'} showAction={true} actionButtonName={'New Form'} onActionClicked={handleNewForm}  />
        </div>
        <div className="card-body d-flex flex-row" style={{ alignItems: 'flex-start' }}>
          <div className="table-responsive w-100">
            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0 w-100">
              <thead>
              <tr className="fs-7 fw-bold text-gray-400 border-bottom-0 mt-5">
                <th className="p-0 pb-3 min-w-200px text-start">FORM NAME</th>
                <th className="p-0 pb-3 min-w-150px text-start">SECTIONS</th>
                <th className="p-0 pb-3 min-w-250px text-start">ORGANIZATION</th>
                <th className="p-0 pb-3 min-w-50px text-end">EDIT</th>
              </tr>
              </thead>

              <tbody>
              {dummyForms.map((item) => (
                <FormRow key={item.id}
                         form={item}
                         onEdit={() => handleEditForm(item)}
                         onDelete={null}
                />
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default FormList