import Box from "@mui/material/Box";
import { FormControl, Grid, Select } from "@mui/material";
import React from "react";
import casesStore from "../../../caseStore";
import { CaseType } from "../../../../../shared/models/caseType.model";
import CustomLoadingDots from "../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import { useSnapshot } from "valtio";
import SelectableTile
  from "../../../../inspections/components/edit-inspection/components/edit-inspected-entity/components/space-selection/components/selectable-tiles/SelectableTile";
import MenuItem from "@mui/material/MenuItem/MenuItem";


const CaseTypeSelection = ({manual = false}) => {
  const snap = useSnapshot(casesStore)

  const handleCaseTypeChange = (type: CaseType) => {
    console.log(type)
    casesStore.caseToEdit.case_type = type;
    casesStore.handleFetchForm(type.id, manual).then(() => casesStore.activeSection++);
  };

  return (
    <FormControl fullWidth style={{ marginBottom: 15 }}>
      <Box>
        {snap.caseTypesLoading ? (
          <CustomLoadingDots />
        ) : (
          <div>
            {!manual ? (
              <Grid container>
                {casesStore.availableCaseTypes.map((data, index) => (
                  <SelectableTile
                    key={index}
                    disabled={false}
                    data={data}
                    selectedIndex={-1}
                    onTileClicked={handleCaseTypeChange}
                    icon={'abstract-13'}
                    sageModal={true}
                  />
                ))}
              </Grid>
            ) : (
              <>
                <span className="text-gray-700">Case Type</span>
                <Select
                  sx={{width: "100%"}}
                  value={snap.caseToEdit.case_type?.name || ""}
                  disabled={snap.caseToEdit.department.id === 0}
                >
                  {casesStore.availableCaseTypes.map((type) => (
                    <MenuItem key={type.id} value={type.name} onClick={() => handleCaseTypeChange(type)}>{type.name}</MenuItem>
                  ))}
                </Select>
              </>
            )}
          </div>
        )}
      </Box>
    </FormControl>
  );
};

export default CaseTypeSelection;