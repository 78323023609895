import React, { useEffect, useState } from "react";
import {
  useMediaQuery, useTheme
} from "@mui/material";
import useAppStore from "../../../../../../appStore";
import { Media } from "../../../../../../shared/models/media.model";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import casesStore from "../../../../caseStore";
import DynamicCaseFormSection from "../../../dynamic-form/DynamicCaseFormSection";
import CaseActionModal from "../case-action/CaseActionModal";
import CustomLoadingDots from "../../../../../../shared/components/page/popup-dialog/CustomLoadingDots";

const CaseForm = () => {
  const {t} = useTranslation();
  const {toggleMasterRefresh} = useAppStore();
  const snap = useSnapshot(casesStore);
  const [media, setMedia] = useState<Media[]>(casesStore.caseToEdit.media);
  const isMobile = useMediaQuery(useTheme().breakpoints.only('xs'));


  useEffect(() => {
    casesStore.caseToEdit.media = media;
  }, [media]);


  const handleFinishSubmission = () => {
    casesStore.postCreationModalOpen = false;
    toggleMasterRefresh();
    casesStore.handleCloseCaseModal();
  };


  return (
    <div className="flex-column-fluid" style={{ marginRight: isMobile ? '' : '75px', minHeight: '100vh' }}>
      <div style={{ marginBottom: "15px" }}>
        <h3 className="card-title align-items-start flex-column">
          <span
            className="card-label fw-bold text-gray-700">{`${t('case-details')}`}</span>
        </h3>
      </div>

      {snap.formLoading ? (
        <CustomLoadingDots />
      ) : (
        <>
          {snap.formSections.map((section) => (
            <DynamicCaseFormSection section={section} manual={true} />
          ))}
        </>
      )}

      {snap.postCreationModalOpen && (
        <CaseActionModal open={snap.postCreationModalOpen} sections={casesStore.postCreationSections} onClose={handleFinishSubmission} />
      )}

    </div>
  );
};

export default CaseForm;


