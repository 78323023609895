import { useSnapshot } from "valtio/index";
import caseTypeStore from "../../case-types/caseTypeStore";
import useAppStore from "../../../../../appStore";
import React from "react";
import { Box, Button, TextField, Checkbox, FormControlLabel, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import FormSectionFieldRow from "./FormSectionFieldRow";

const EditFormSection = () => {
  const snap = useSnapshot(caseTypeStore);
  const { whiteLabelProps } = useAppStore();

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (caseTypeStore.selectedFormSection) {
      caseTypeStore.selectedFormSection.name = value;
    }
  };

  const handlePromptChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (caseTypeStore.selectedFormSection) {
      caseTypeStore.selectedFormSection.sage_prompt = value;
    }
  };

  const handleShowAfterCreationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (caseTypeStore.selectedFormSection) {
      caseTypeStore.selectedFormSection.show_after_creation = event.target.checked;
    }
  };

  const handleSequenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (caseTypeStore.selectedFormSection) {
      caseTypeStore.selectedFormSection.sequence = parseInt(value, 10) || 0;
    }
  };

  const handleSaveSection = () => {
    // TODO section save
  };

  const handleEditFormField = () => {
    // ...
  };

  const handleDeleteFormField = () => {
    // ...
  };

  return (
    <Box className="mb-5" style={{ position: "sticky", top: 0, zIndex: 100 }}>
      {snap.selectedFormSection && snap.selectedFormSection.id === 0 ? (
        <div
          className="d-flex justify-content-between align-items-center mb-4"
          style={{ marginLeft: "15px" }}
        >
          <h3
            style={{
              fontSize: "1.1rem",
              fontWeight: 200,
              fontStyle: "italic",
              color: whiteLabelProps.primary_color,
            }}
          >
            <InfoIcon
              sx={{
                fontSize: "2rem",
                color: whiteLabelProps.primary_color,
                marginRight: "5px",
              }}
            />
            New Section: Fill out details and add desired fields
          </h3>
          <Button variant="contained" color="primary" onClick={handleSaveSection}>
            Save Section
          </Button>
        </div>
      ) : (
        <div
          className="d-flex justify-content-between align-items-center mb-4"
          style={{ marginLeft: "15px" }}
        >
          <h3>Edit Section</h3>
          <Button variant="contained" color="primary" onClick={handleSaveSection}>
            Save Section
          </Button>
        </div>
      )}

      <span
        className="text-gray-700"
        style={{ fontSize: "1rem", marginLeft: "15px" }}
      >
        Details
      </span>
      <div
        className="card card-flush h-md-100 mt-1 mb-10 p-10"
        style={{ marginLeft: "15px" }}
      >

        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            required
            placeholder="Name"
            label='Section Name'
            value={caseTypeStore.selectedFormSection?.name || ""}
            onChange={handleNameChange}
          />
        </Box>

        {/* Sage Prompt Field */}
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            required
            placeholder="Sage Prompt"
            label='Sage Prompt'
            value={caseTypeStore.selectedFormSection?.sage_prompt || ""}
            onChange={handlePromptChange}
          />
        </Box>

        {/* Show After Creation & Sequence */}
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <TextField
            label="Sequence"
            type="number"
            value={snap.selectedFormSection?.sequence || ""}
            onChange={handleSequenceChange}
            sx={{ width: 150, mr: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={snap.selectedFormSection?.show_after_creation || false}
                onChange={handleShowAfterCreationChange}
              />
            }
            label="Show After Creation"
          />
        </Box>

      </div>

        {/* Fields Table */}
        <span
          className="text-gray-700"
          style={{ fontSize: "1rem", marginLeft: "15px" }}
        >
        Fields
      </span>
        <div
          className="card card-flush h-md-100 mt-1 mb-10"
          style={{ marginLeft: "15px" }}
        >
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-row-dashed align-middle gs-0 gy-3">
                <thead>
                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                  <th className="p-0 pb-3 min-w-150px text-start">Name</th>
                  <th className="p-0 pb-3 min-w-25px text-start">Type</th>
                  <th className="p-0 pb-3 min-w-50px text-start px-3">Source</th>
                </tr>
                </thead>
                <tbody>
                {caseTypeStore.selectedFormSection?.fields.map((field, index) => (
                  <FormSectionFieldRow
                    key={field.id}
                    field={field}
                    onEdit={handleEditFormField}
                    onDelete={handleDeleteFormField}
                  />
                ))}
                </tbody>
              </table>
              <Button
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "left",
                  textAlign: "left",
                  fontSize: "1.3rem",
                }}
              >
                Add Field...
              </Button>
            </div>
          </div>
        </div>
    </Box>
);
};

export default EditFormSection;
