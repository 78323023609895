import React, { useEffect, useRef, useState, FC } from 'react';
import {
  AppBar, Button, Dialog, DialogActions, IconButton, Typography, Toolbar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ModalTransition from '../../../../../../../shared/components/page/transitions/ModalTransition';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import WherePage from './WherePage';
import WhatPage from './WhatPage';
import { WhereAndWhatModel } from "../../../../../../../shared/models/whereAndWhat.model";
import { Entity } from "../../../../../../../shared/models/entity.model";
import { EntityType } from "../../../../../../../shared/models/entityType.model";
import CustomError from "../../../../../../../shared/components/page/popup-dialog/CustomError";
import { WorkItem } from "../../../../../../../shared/models/workItem.model";
import { workItemStore } from "../../../../../workItemStore";
import casesStore from "../../../../../../cases/caseStore";

interface EditWhereAndWhatProps {
  open: boolean;
  onClose: () => void;
  whereAndWhat: WhereAndWhatModel[];
  setWhereAndWhat: (whereAndWhat: WhereAndWhatModel[]) => void;
  module: string;
  createPrefill?: (workItem: WorkItem) => void;
}

const EditWhereAndWhat: FC<EditWhereAndWhatProps> = ({ open, onClose, whereAndWhat, setWhereAndWhat, module, createPrefill }) => {
  const [activeStep, setActiveStep] = useState(0);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<any>({
    space: new Entity(),
    floor: new Entity(),
    spaceType: new EntityType(),
    servicesToPerform: [],
  });

  const [error, setError] = useState('');

  useEffect(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = 0;
    }
  }, [activeStep, error]);

  const handleAddEntry = (): void => {
    const newEntry: WhereAndWhatModel = { entity: state.floor, entity_type: state.spaceType, service_items: state.servicesToPerform };
    newEntry.entity.entity_path = [state.space];
    const updatedWhereAndWhat = [...whereAndWhat, newEntry];
    setWhereAndWhat(updatedWhereAndWhat);
    if (module === 'cases') {
      casesStore.caseToEdit.where_and_what = updatedWhereAndWhat;
    } else {
      workItemStore.workItemToEdit.where_and_what = updatedWhereAndWhat;
    }
    if (createPrefill) {
      createPrefill(workItemStore.workItemToEdit);
    }
    resetStates();
    handleClose();
  };


  const handleClose = (): void => {
    onClose();
    setState(prev => ({ ...prev, space: new Entity() }));
    resetStates();
  };

  const resetStates = (): void => {
    setActiveStep(0);
    setState(prev => ({ ...prev, floor: new Entity() }));
    setState(prev => ({ ...prev, spaceType: new Entity() }));
    setState(prev => ({ ...prev, serviceToPerform: [] }));
  };

  const handleNextClicked = (): void => {
    const conditions = [
      { check: state.space.id === 0, message: 'Building is required' },
      { check: state.space.children.length !== 0, message: 'Building set up incorrectly choose a new building' },
      { check: state.floor.id === 0, message: 'Floor is required' },
      { check: state.spaceType.id === 0, message: 'Space Type is required' }
    ];

    for (const condition of conditions) {
      if (condition.check) {
        setError(condition.message);
        return;
      }
    }

    setError('');
    setActiveStep(1);
  };


  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={ModalTransition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Button color="inherit" variant="contained" onClick={onClose} sx={{ color: '#444444', display: 'none' }}>
            Exit
          </Button>
        </Toolbar>
      </AppBar>
      <div className='card mt-5' style={{ height: '70vh' }}>
        <div ref={scrollableContainerRef} className="card-body" style={{ overflowY: 'auto' }}>
          {error &&
            <CustomError error={error} />
          }
          <SwipeableViews disabled={true} index={activeStep}>
            <WherePage {...{ state, setState}} />
            <WhatPage {...{ state, setState }} />
          </SwipeableViews>
        </div>
      </div>
      <DialogActions sx={{ position: 'sticky', bottom: 0, backgroundColor: '#fafafa', zIndex: 2 }}>
        <Button disabled={activeStep === 0} onClick={() => setActiveStep(0)}>Prev</Button>
        <Button disabled={activeStep === 1} onClick={handleNextClicked}>Next</Button>
        <Button variant="contained" color="primary" onClick={handleAddEntry} disabled={activeStep === 0}>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditWhereAndWhat;

