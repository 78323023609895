export class CaseForm {
  id: number;
  name: string;
  description: string;
  form: DynamicCaseForm;

  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.form = new DynamicCaseForm();
  }
}

export class DynamicCaseForm {
  id: number;
  name: string;
  description: string;
  sections: Section[];

  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.sections = [];
  }
}

export class Section {
  id: number;
  name: string;
  sage_prompt: string;
  fields: Field[];
  show_after_creation: boolean;
  sequence: number;

  constructor() {
    this.id = 0;
    this.name = '';
    this.sage_prompt = '';
    this.fields = [];
    this.show_after_creation = false;
    this.sequence = 0;
  }
}

export class Field {
  id: number;
  name: string;
  type: string;
  data_source: string;
  is_required: boolean;
  is_enabled: boolean;

  constructor() {
    this.id = 0;
    this.name = '';
    this.type = '';
    this.data_source = '';
    this.is_enabled = false;
    this.is_required = false;
  }
}
