import { proxy } from 'valtio';
import { CaseForm, DynamicCaseForm, Section } from "../../../../shared/models/caseForm.model";
import { CaseType } from "../../../../shared/models/caseType.model";
import { CasesService } from "../../../../shared/services/cases/casesService";

interface CaseTypeStore {
  caseTypes: CaseType[];
  selectedCaseType: CaseType | null;
  formToEdit: CaseForm | null;
  selectedFormSection: Section | null;
  formBuildOpen: boolean;
  formSelectOpen: boolean;
  typesLoading: boolean;
  error: string;
  handleFetchCaseTypes: (provider_id: number) => Promise<void>;
}

const caseTypeStore = proxy<CaseTypeStore>({
  caseTypes: [],
  selectedCaseType: null,
  formToEdit: null,
  selectedFormSection: null,
  formBuildOpen: false,
  formSelectOpen: false,
  typesLoading: false,
  error: '',
  handleFetchCaseTypes: async (provider_id: number) => {
    try {
      caseTypeStore.typesLoading = true;
      const service = new CasesService();
      caseTypeStore.caseTypes = await service.getCaseTypes(provider_id, 0);
    } catch (error) {
      if (error instanceof Error) {
        caseTypeStore.error = error.message;
      }
    } finally {
      caseTypeStore.typesLoading = false;
    }
  },
});

export default caseTypeStore;