import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { WhereAndWhatModel } from "../../../../../shared/models/whereAndWhat.model";
import Box from "@mui/material/Box";
import EditWhereAndWhat
  from "../../../../work-items/components/edit-work-item/components/where-and-what/edit-where-and-what/EditWhereAndWhat";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import WhereAndWhatRow
  from "../../../../work-items/components/edit-work-item/components/where-and-what/WhereAndWhatRow";
import casesStore from "../../../caseStore";
import { useSnapshot } from "valtio";

const CaseWhereAndWhat = ({editing=true}) => {

  const caseSnap = useSnapshot(casesStore);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [whereAndWhat, setWhereAndWhat] = useState<WhereAndWhatModel[]>([]);

  useEffect(() => {
    if (
      whereAndWhat?.length === 0 &&
      casesStore.caseToEdit?.where_and_what?.length !== 0
    ) {
      setWhereAndWhat(casesStore.caseToEdit.where_and_what);
    }
  }, [caseSnap.caseToEdit.where_and_what, whereAndWhat?.length]);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDelete = (index: number) => {
    const updatedWhereAndWhat = whereAndWhat.filter((_, i) => i !== index);
    setWhereAndWhat(updatedWhereAndWhat);
    casesStore.caseToEdit.where_and_what = updatedWhereAndWhat;
  };

  return (
    <Box component={Paper} variant={"outlined"} sx={{padding: '10px'}}>
      <Box marginBottom={2}>

        <EditWhereAndWhat
          open={isDialogOpen}
          onClose={handleCloseDialog}
          whereAndWhat={whereAndWhat}
          setWhereAndWhat={setWhereAndWhat}
          module={'cases'}
        />

      </Box>

      {/* Table of "Where and What" Rows */}
      <TableContainer component={Paper} variant="outlined" sx={{marginTop: -2}}>
        <Table size="small" aria-label="where-and-what-table" sx={{minHeight: '50px'}}>
          <TableBody>
            {whereAndWhat?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography variant="body2">
                    {t("nothing-specified")}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              whereAndWhat.map((entry, index) => (
                <WhereAndWhatRow
                  key={index}
                  entry={entry}
                  isMobile={isMobile}
                  isEditing={editing}
                  onDelete={() => handleDelete(index)}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {editing &&
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenDialog}
          sx={{ mt: 1 }}
        >
          {t("add")} ...
        </Button>
      }

    </Box>
  );
};

export default CaseWhereAndWhat;