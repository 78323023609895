import React from "react";
import useAppStore from "../../../appStore";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { workItemStore } from "../../work-items/workItemStore";
import { WorkItem } from "../../../shared/models/workItem.model";
import casesStore from "../../cases/caseStore";
import DuotoneCustomIcon from "../../../shared/components/page/row-icons/DuotoneCustomIcon";
import ActionButton from "../../../shared/components/buttons/ActionButton";
import CaseWorkItem from "../../cases/components/edit-case/components/details/case-work-item/CaseWorkItem";

const CreatedCaseCard = (props) => {
  const { item, workItem=true } = props;
  const [open, setOpen] = React.useState(false);
  const {t} = useTranslation();
  const {appImages} = useAppStore();
  const caseTypeImageMap = {
    "General HR Questions": appImages.iconCaseQuestion,
    "Resignation": appImages.iconResignation,
    "Supervisor Incident Intake Form": appImages.iconSafetyCase,
    "Hey Sage General Help": appImages.iconCaseTellSage,
    "Complaint": appImages.iconComplaint,
    "Compliment": appImages.iconCompliment
  };

  const openWorkItem = (id) => {
    let workItem = new WorkItem();
    workItem.id = id;
    workItemStore.workItemToEdit = workItem;
    workItemStore.open = true;
  }

  const cardImage = caseTypeImageMap[item.case_type.name] || appImages.iconGenericCase;
  const caseColor = item.case_type.name === 'Complaint' ? 'bg-light-danger' : 'bg-light-success';
  const caseTitle = item?.case_type?.name;
  const caseDepartment = item?.department?.name;

  const handleCreateWorkItem = () => {
    workItemStore.workItemToEdit = new WorkItem();
    setOpen(true);
  }

  const handleClose = () => {
    workItemStore.workItemToEdit = new WorkItem();
    setOpen(false);
  };

  return (
    <div className="case-card">
      <div className="card-header">
        <DuotoneCustomIcon photo={cardImage} photoHeight={'57px'} photoWidth={'57px'} colorClass={caseColor} />
        <div className="case-details">
          <h2 className="case-title">{caseTitle}</h2>
          <p className="case-department text-muted">{caseDepartment}</p>
          <p className="case-number">#{item.case_number}</p>
        </div>
        <div className="ranking-section">
          {item.has_work_items &&
            <ActionButton
              iconType={item.work_item_status === 'Closed' ? "work_done" : "work_pending"}
              onClick={(e) => {
                e.preventDefault();
                openWorkItem(item.work_item_id);
              }}
            />
          }
        </div>
      </div>

      {workItem &&
        <div className="card-body-2">
          {!item.has_work_items ? (
            <Button variant={'contained'} sx={{ width: '100%' }}
                    onClick={handleCreateWorkItem}>{t('create-work-item')}</Button>
          ) : (
            <Button sx={{ width: '100%' }}>Work Item Created</Button>
          )}
        </div>
      }

      {open &&
        <CaseWorkItem open={open} onActionClicked={handleClose} selectedCase={casesStore.caseToEdit} />
      }
    </div>
  );
};


export default CreatedCaseCard;
