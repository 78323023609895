export const dummyForms = [
  {
    id: 1,
    name: "Default Form",
    description: "$default",
    form: {
      id: 2,
      name: "Default Form",
      description: "Collect basic information about a case.",
      sections: [
        {
          id: 2,
          name: "Details",
          sage_prompt: "Provide your feedback in the fields below.",
          fields: [
            {
              id: 3,
              name: "Topic",
              type: "Text",
              data_source: 'topic',
            },
            {
              id: 4,
              name: "Description",
              type: "Textarea",
              data_source: '$model.description',
            },
            {
              id: 5,
              name: "Related Employees",
              type: "EmployeeSelect",
              data_source: '$model.related_to_employees',
            },
          ],
        },
        {
          id: 3,
          name: "Case Work Item",
          sage_prompt: "Would you like to create a work item.",
          fields: [
            {
              id: 3,
              name: "Work Item",
              type: "WorkItemCreation",
              data_source: '',
            },
          ],
        },
      ],
    },
    caseType: "Verde",
    fieldCount: 2,
  },
  {
    id: 2,
    name: "Incident Intake Form",
    description: "incident_intake_form",
    form: {
      id: 1,
      name: "Supervisor Incident Intake Form",
      description: "Supervisor Incident Intake Form",
      sections: [
        {
          id: 1,
          name: "Safety Detail Form",
          sage_prompt: "Please fill out this intake form",
          fields: [
            {
              id: 1,
              name: "Nature of Incident",
              type: "Select",
              data_source: "injury_illness_type",
            },
            {
              id: 2,
              name: "Location of Incident",
              type: "Text",
              data_source: null,
            },
          ],
        },
        {
          id: 2,
          name: "Case Work Item",
          sage_prompt: "Would you like to create a work item.",
          fields: [
            {
              id: 3,
              name: "Work Item",
              type: "WorkItemCreation",
              data_source: '',
            },
          ],
        },
      ],
    },
    caseType: "Verde",
    fieldCount: 2,
  },
  {
    id: 3,
    name: "Complaint Form",
    description: "complaint_form",
    form: {
      id: 3,
      name: "Complaint Detail Form",
      description: "Document visitor entries for safety and compliance.",
      sections: [
        {
          id: 3,
          name: "Complaint Details",
          sage_prompt: "Please provide visitor details below.",
          fields: [
            {
              id: 5,
              name: "Visitor Name",
              type: "Text",
              data_source: null,
            },
            {
              id: 6,
              name: "Purpose of Visit",
              type: "Select",
              data_source: "visit_purpose",
            },
            {
              id: 7,
              name: "Entry Time",
              type: "Time",
              data_source: null,
            },
          ],
        },
        {
          id: 2,
          name: "Case Work Item",
          sage_prompt: "Would you like to create a work item.",
          fields: [
            {
              id: 3,
              name: "Work Item",
              type: "WorkItemCreation",
              data_source: '',
            },
          ],
        },
      ],
    },
    caseType: "Verde",
    fieldCount: 3,
  },
  {
    id: 4,
    name: "Compliment Form",
    description: "compliment_form",
    form: {
      id: 3,
      name: "Visitor Log Form",
      description: "Document visitor entries for safety and compliance.",
      sections: [
        {
          id: 3,
          name: "Compliment Details",
          sage_prompt: "Please provide visitor details below.",
          fields: [
            {
              id: 5,
              name: "Feedback",
              type: "Text",
              data_source: null,
            },
            {
              id: 6,
              name: "Related Employees",
              type: "EmployeeSelect",
              data_source: "$model.related_to_employees",
            },
            {
              id: 7,
              name: "Entry Time",
              type: "Time",
              data_source: null,
            },
          ],
        },
        {
          id: 2,
          name: "Case Work Item",
          sage_prompt: "Would you like to create a work item.",
          fields: [
            {
              id: 3,
              name: "Work Item",
              type: "WorkItemCreation",
              data_source: '',
            },
          ],
        },
      ],
    },
    caseType: "Verde",
    fieldCount: 3,
  },
];
