import ModalTransition from "../../../../../shared/components/page/transitions/ModalTransition";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Toolbar
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";
import CustomLoadingDots from "../../../../../shared/components/page/popup-dialog/CustomLoadingDots";
import SelectableTilesGrid
  from "../../../../inspections/components/edit-inspection/components/edit-inspected-entity/components/space-selection/components/selectable-tiles/SelectableTilesGrid";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { serviceLogStore } from "../serviceLogStore";
import useAppStore from "../../../../../appStore";

const WhereAndWhatFilter = ({open, onClose}) => {
  const {selectedLocation} = useAppStore();
  const {t} = useTranslation();
  const snap = useSnapshot(serviceLogStore);

  useEffect(() => {
    if (selectedLocation.id !== 0) {
      serviceLogStore.handleFetchBuildings(selectedLocation.id).then();
    }
  }, [selectedLocation.id]);

  useEffect(() => {
    if (selectedLocation.id !== 0 && snap.filteredFloor && snap.filteredFloor?.id !== 0) {
      serviceLogStore.handleFetchTypes(selectedLocation.id, snap.filteredFloor.id).then();
    }
  }, [selectedLocation.id, snap.filteredFloor?.id]);

  useEffect(() => {
    if (selectedLocation.id !== 0 && snap.filteredBuilding && snap.filteredBuilding?.id !== 0) {
      serviceLogStore.handleFetchFloors(selectedLocation.id, snap.filteredBuilding.id).then();
    }
  }, [selectedLocation.id, snap.filteredBuilding]);

  const handleBuildingChanged = (event) => {
    const selectedBuildingName = event.target.value;
    serviceLogStore.filteredBuilding = serviceLogStore.buildings.find(bldg => bldg.name === selectedBuildingName) || null;
  };

  const handleEntityTypeChanged = (event, spaceType) => {
    serviceLogStore.filteredType = spaceType;
  };

  if (snap.buildingsLoading) {
    return (<CustomLoadingDots />);
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      TransitionComponent={ModalTransition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent dividers sx={{ maxHeight: "70vh", overflowY: "auto" }}>
        <div className="d-flex flex-column flex-row-fluid" style={{ paddingBottom: "150px" }}>
          <h1>{t('select-space')}</h1>
          <>
            <div className='mt-10'>
              <h3>{t('what-building')}</h3>
              <FormControl style={{ width: '100%' }} margin="normal">
                <Select
                  value={snap.filteredBuilding ? snap.filteredBuilding.name : ''}
                  onChange={handleBuildingChanged}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        width: '80%',
                        height: '70%',
                      },
                    },
                  }}
                >
                  {serviceLogStore.buildings.map((bldg) => (
                    <MenuItem key={bldg.id} value={bldg.name}>
                      {bldg.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {snap.floors.length > 0 && (
              <div className="mt-10">
                <h3>{t('what-floor')}</h3>
                {snap.floorsLoading ? (
                  <div style={{ marginTop: "15px" }}>
                    <CustomLoadingDots />
                  </div>
                ) : (
                  <>
                    {snap.filteredBuilding !== null ? (
                      <div className="mt-10">
                        <SelectableTilesGrid disabled={false}
                                             options={serviceLogStore.floors}
                                             value={snap.filteredFloor}
                                             onChange={(event, selectedEntity) => {
                                               if (selectedEntity) {
                                                 serviceLogStore.filteredFloor = selectedEntity;
                                               }
                                             }}
                                             sageModal={true}
                        />
                      </div>
                    ) : (
                      <p>{t("choose-building")}</p>
                    )}
                  </>

                )}
              </div>
            )}

            <>
              <div className="mt-10" style={{ width: "100%" }}>
                <h3>{t('select-space-type')}</h3>
                {snap.typesLoading ? (
                  <div style={{ marginTop: "15px" }}>
                    <CustomLoadingDots />
                  </div>
                ) : (
                  <>
                    {snap.filteredFloor !== null ? (
                      <div className="mt-10">
                        <SelectableTilesGrid disabled={false}
                                             options={serviceLogStore.types}
                                             value={snap.filteredType}
                                             onChange={handleEntityTypeChanged}
                                             sageModal={true}
                        />
                      </div>
                    ) : (
                      <p>{t("choose-floor")}</p>
                    )}
                  </>
                )}
              </div>
            </>
          </>
        </div>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          variant="contained"
          onClick={onClose}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default WhereAndWhatFilter;