import React, { useState } from 'react';
import { ScannerService } from "../../shared/services/qr-scanner/scannerService";
import { Scanner } from '@yudiel/react-qr-scanner';
import useAppStore from "../../appStore";
import QrScanResult from "../dashboard/client-dashboard/service-log-widget/QrScanResult";

const QrCodeReader = ({ onClose }) => {
  const user = useAppStore((state) => state.shadowUser ?? state.loggedInUser);
  const {appImages} = useAppStore();

  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [scanResponse, setScanResponse] = useState(null);
  const [scanImage, setScanImage] = useState('');

  async function handleScan(result) {
    if (!result?.length) return;

    try {
      const response = await new ScannerService().sendScanData(
        user.employee,
        result[0].rawValue
      );
      setScanResponse(`Thank you for scanning ${response?.entity?.name}!`);
      setScanImage(appImages.iconScanSuccess)
      setResultDialogOpen(true);
    } catch (e) {
      setScanResponse("Error during scan. This sensor may not be set up yet" );
      setScanImage(appImages.iconScanError)
      setResultDialogOpen(true);
    }
  }

  const handleCloseDialog = () => {
    setResultDialogOpen(false);
    onClose(scanResponse);
  };

  return (
    <div>
      {!resultDialogOpen && <Scanner onScan={handleScan} />}

      {resultDialogOpen && (
        <QrScanResult
          open={resultDialogOpen}
          onClose={handleCloseDialog}
          image={scanImage}
          message={scanResponse}
        />
      )}
    </div>
  );
};

export default QrCodeReader;


