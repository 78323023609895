import useAppStore from "../../../../../appStore";
import React, { useState } from "react";
import { Box } from "@mui/material";
import ActionButton from "../../../../../shared/components/buttons/ActionButton";
import DuotoneCustomIcon from "../../../../../shared/components/page/row-icons/DuotoneCustomIcon";

const FormRow = (props) => {
  const { form, onEdit, onDelete } = props;

  const { whiteLabelProps, toggleMasterRefresh, appImages } = useAppStore();
  const [deleting, setDeleting] = useState(false);

  return (
    <>
      <tr>
        <td>
          <div className="d-flex align-items-center">
            <DuotoneCustomIcon photo={appImages.iconForm} photoHeight={'50px'} photoWidth={'50px'} colorClass={'bg-light-success'} />
            <div className="d-flex justify-content-start flex-column ms-3">
              <a className="text-gray-800 fw-bold mb-1 fs-6">
                {form?.name}
              </a>
              <span className="text-gray-400 fw-semibold d-block fs-7">
                {form?.description}
              </span>
            </div>
          </div>
        </td>

        <td>
          <span className="text-gray-800 fw-bold d-block">
            {form?.fieldCount}
          </span>
        </td>

        <td>
          <span className="text-gray-800 fw-bold d-block">
            {form?.caseType}
          </span>
        </td>

        <td>
          <div className="d-flex justify-content-end">
            <ActionButton
              iconType="pencil"
              onClick={(e) => {
                e.preventDefault();
                onEdit(form);
              }}
            />
          </div>
        </td>
      </tr>
    </>
  );
};

export default FormRow;
